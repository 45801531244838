import { DeleteOutlined, EditOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Modal } from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Fragment, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { BsChevronDown } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa6';
import { FiPlus } from 'react-icons/fi';
import { GoCheckCircleFill, GoChevronDown, GoChevronUp } from 'react-icons/go';
import { HiOutlineDownload } from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import CreateKnowledgeBaseModal from '../components/knowledge-base/create-knowledge-base-modal.tsx';
import UpdateKnowledgeBaseModal from '../components/knowledge-base/update-knowledge-base-modal.tsx';
import { getIconsForType } from '../components/knowledge-base/utils.tsx';
import Loader from '../components/Loader';
import axios from '../config/axios.ts';
import { cn } from '../config/cn';
import AppLayout from '../layouts/app.layout';

export type TKnowledgeBase = {
	knowledge_base_id: string;
	name: string;
	created_at: Date;
	updated_at: Date;
	sources: Source[];
	status: 'pending' | 'ready';
};

export type Source = {
	source_id: string;
	type: 'text' | 'url' | 'file';
	name: null | string;
	url: null | string;
	created_at: Date;
	updated_at: Date;
	status: 'pending' | 'ready' | 'error';
};

const KnowledgeBase = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = useState(1);
	const [copied, setCopied] = useState(false);
	const [knowledgeBaseListModalOpen, setKnowledgeBaseListModalOpen] = useState(false);
	const [createKnowledgeBaseModalOpen, setCreateKnowledgeBaseModalOpen] = useState(false);
	const [updateKnowledgeBaseModalOpen, setUpdateKnowledgeBaseModalOpen] = useState(false);
	const [modal, contextHolder] = Modal.useModal();
	const [collapsed, setCollapsed] = useState(false);
	const [kbListLoading, setKbListLoading] = useState(false);
	const [kbList, setKbList] = useState<TKnowledgeBase[]>([]);
	const [count, setCount] = useState(0);
	const [kbDetailsLoading, setKbDetailsLoading] = useState(false);
	const [kbDetails, setKbDetails] = useState<TKnowledgeBase | null>(null);
	const [refresh, setRefresh] = useState(1);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [downloadLoadingID, setDownloadLoadingID] = useState<string | null>(null);

	const handleRefresh = () => {
		setRefresh((prev) => prev + 1);
	};

	const handleCollapse = () => {
		setCollapsed(!collapsed);
	};

	// get all knowledge base
	useEffect(() => {
		(async () => {
			try {
				setKbListLoading(true);
				const { data } = await axios.get('/knowledge-base', {
					withCredentials: true,
					params: {
						page,
						limit: 20,
					},
				});
				setCount(data.count);
				if (page === 1) {
					setKbList(data.results);
				} else {
					setKbList((prev) => [...prev, ...data.results]);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setKbListLoading(false);
			}
		})();
	}, [page, refresh]);

	// set first knowledge base as default
	useEffect(() => {
		if (!searchParams.get('knowledge-base') && kbList.length > 0) {
			setSearchParams({
				'knowledge-base': String(kbList[0].knowledge_base_id),
			});
		}
	}, [kbList, searchParams, setSearchParams]);

	// get knowledge base id from url
	const selectedKB = searchParams.get('knowledge-base');

	// get knowledge base details
	useEffect(() => {
		setKbDetailsLoading(true);
		(async () => {
			if (selectedKB) {
				try {
					const { data } = await axios.get(`/knowledge-base/${selectedKB}`, { withCredentials: true });
					setKbDetails(data.results);
				} finally {
					setKbDetailsLoading(false);
				}
			}
		})();
	}, [selectedKB]);

	// reset copied state after 2 seconds
	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [copied]);

	// group sources by type
	const groupedSources = useMemo(() => {
		if (!kbDetails) return { urlSources: {}, otherSources: [] };
		const urlSources: Record<string, typeof kbDetails.sources> = {};
		const otherSources: typeof kbDetails.sources = [];

		kbDetails.sources.forEach((source) => {
			if (source.type === 'url') {
				const domain = new URL(source.url as string).hostname;
				if (!urlSources[domain]) urlSources[domain] = [];
				urlSources[domain].push(source);
			} else {
				otherSources.push(source);
			}
		});

		return { urlSources, otherSources };
	}, [kbDetails]);

	const deleteKnowledgeBase = async (knowledgeBaseId: string) => {
		try {
			await axios.delete(`/knowledge-base/${knowledgeBaseId}`, { withCredentials: true });

			// Filter out deleted KB
			const updatedList = kbList.filter((kb) => kb.knowledge_base_id !== knowledgeBaseId);

			// Update search params if there are remaining KBs
			if (updatedList.length > 0) {
				setSearchParams({
					'knowledge-base': String(updatedList[0].knowledge_base_id),
				});
			} else {
				// Clear search params if no KBs left
				setSearchParams({});
			}

			toast.success('Knowledge Base Deleted');
			handleRefresh();
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('Error Deleting Knowledge Base');
			}
		}
	};

	// color based on url source status
	const getAggregateStatus = (sources: Source[]) => {
		if (!sources.length) return 'pending';

		const hasError = sources.some((source) => source.status === 'error');
		const hasPending = sources.some((source) => source.status === 'pending');
		const allReady = sources.every((source) => source.status === 'ready');
		const allError = sources.every((source) => source.status === 'error');

		if (allReady) return 'ready';
		if (allError) return 'error';
		if (hasError || hasPending) return 'pending';
		return 'pending';
	};

	return (
		<AppLayout
			title="Knowledge Base"
			subtitle="Manage your knowledge base and improve your AI assistant's performance."
		>
			{contextHolder}

			{kbListLoading && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full items-center justify-center">
						<Loader />
					</div>
				</Card>
			)}

			{!kbListLoading && kbList.length === 0 && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full items-center justify-center">
						<div className="flex flex-col gap-2 text-center">
							<div className="text-base font-medium text-gray-600 dark:text-white">You don't have any knowledge base.</div>
							<Button
								type="primary"
								onClick={() => {
									setCreateKnowledgeBaseModalOpen(true);
								}}
							>
								Create One
							</Button>
						</div>
					</div>
				</Card>
			)}

			{!kbListLoading && kbList.length > 0 && (
				<div className="flex flex-col gap-6 min-[895px]:flex-row">
					{/* mobile version */}
					<div className="min-[895px]:hidden">
						{kbList.map((item, i) => {
							if (item.knowledge_base_id === searchParams.get('knowledge-base')) {
								return (
									<Card
										key={i}
										styles={{ body: { padding: 10 } }}
										role="button"
										className="shadow-sm"
										onClick={() => {
											// open modal
											setKnowledgeBaseListModalOpen(true);
										}}
									>
										<div className="flex items-center justify-between gap-2">
											<div className="flex items-center gap-3">
												<svg
													fill="#B18EED"
													width="24px"
													height="24px"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
													data-name="Layer 1"
												>
													<path d="M22,11A4,4,0,0,0,20,7.52,3,3,0,0,0,20,7a3,3,0,0,0-3-3l-.18,0A3,3,0,0,0,12,2.78,3,3,0,0,0,7.18,4L7,4A3,3,0,0,0,4,7a3,3,0,0,0,0,.52,4,4,0,0,0-.55,6.59A4,4,0,0,0,7,20l.18,0A3,3,0,0,0,12,21.22,3,3,0,0,0,16.82,20L17,20a4,4,0,0,0,3.5-5.89A4,4,0,0,0,22,11ZM11,8.55a4.72,4.72,0,0,0-.68-.32,1,1,0,0,0-.64,1.9A2,2,0,0,1,11,12v1.55a4.72,4.72,0,0,0-.68-.32,1,1,0,0,0-.64,1.9A2,2,0,0,1,11,17v2a1,1,0,0,1-1,1,1,1,0,0,1-.91-.6,4.07,4.07,0,0,0,.48-.33,1,1,0,1,0-1.28-1.54A2,2,0,0,1,7,18a2,2,0,0,1-2-2,2,2,0,0,1,.32-1.06A3.82,3.82,0,0,0,6,15a1,1,0,0,0,0-2,1.84,1.84,0,0,1-.69-.13A2,2,0,0,1,5,9.25a3.1,3.1,0,0,0,.46.35,1,1,0,1,0,1-1.74.9.9,0,0,1-.34-.33A.92.92,0,0,1,6,7,1,1,0,0,1,7,6a.76.76,0,0,1,.21,0,3.85,3.85,0,0,0,.19.47,1,1,0,0,0,1.37.37A1,1,0,0,0,9.13,5.5,1.06,1.06,0,0,1,9,5a1,1,0,0,1,2,0Zm7.69,4.32A1.84,1.84,0,0,1,18,13a1,1,0,0,0,0,2,3.82,3.82,0,0,0,.68-.06A2,2,0,0,1,19,16a2,2,0,0,1-2,2,2,2,0,0,1-1.29-.47,1,1,0,0,0-1.28,1.54,4.07,4.07,0,0,0,.48.33A1,1,0,0,1,14,20a1,1,0,0,1-1-1V17a2,2,0,0,1,1.32-1.87,1,1,0,0,0-.64-1.9,4.72,4.72,0,0,0-.68.32V12a2,2,0,0,1,1.32-1.87,1,1,0,0,0-.64-1.9,4.72,4.72,0,0,0-.68.32V5a1,1,0,0,1,2,0,1.06,1.06,0,0,1-.13.5,1,1,0,0,0,.36,1.37A1,1,0,0,0,16.6,6.5,3.85,3.85,0,0,0,16.79,6,.76.76,0,0,1,17,6a1,1,0,0,1,1,1,1,1,0,0,1-.17.55.9.9,0,0,1-.33.31,1,1,0,0,0,1,1.74A2.66,2.66,0,0,0,19,9.25a2,2,0,0,1-.27,3.62Z" />
												</svg>
												<div className="text-base font-semibold">{item.name}</div>
											</div>

											<div>
												<BsChevronDown size={20} />
											</div>
										</div>
									</Card>
								);
							}
							return null;
						})}

						{/* knowledge bases modal */}
						<Modal
							title="Knowledge Base"
							open={knowledgeBaseListModalOpen}
							onCancel={() => setKnowledgeBaseListModalOpen(false)}
							footer={
								<Button
									onClick={() => {
										setCreateKnowledgeBaseModalOpen(true);
										setKnowledgeBaseListModalOpen(false);
									}}
									block
									icon={<FiPlus size={18} />}
									className="h-9 text-[15px] font-semibold dark:bg-[#1b1827] dark:shadow-none"
								>
									Create
								</Button>
							}
							centered
						>
							<div className="max-h-[calc(100dvh-10rem)] overflow-hidden">
								<div
									className="scrollbar-hidden h-full overflow-y-auto"
									id="kb-list-scrollable-mobile"
								>
									<InfiniteScroll
										dataLength={kbList.length}
										next={
											kbList.length < count
												? () => {
														setPage((prev) => prev + 1);
													}
												: () => {}
										}
										hasMore={count > kbList.length}
										style={{
											overflow: 'hidden',
										}}
										loader={
											<div className="my-3 flex items-center justify-center">
												<Loader />
											</div>
										}
										scrollThreshold={0.9}
										scrollableTarget="kb-list-scrollable-mobile"
									>
										<div className="pt-3" />
										<div className="flex flex-col gap-3.5">
											{kbList.map((item, i) => (
												<div
													key={i}
													className={cn(
														'rounded-lg border bg-white px-2 py-2.5 transition-all duration-300 dark:bg-[#161422]',
														{
															'border-primary': searchParams.get('knowledge-base') === String(item.knowledge_base_id),
															'dark:border-dark-border':
																searchParams.get('knowledge-base') !== String(item.knowledge_base_id),
														},
													)}
													onClick={() => {
														setSearchParams({
															'knowledge-base': String(item.knowledge_base_id),
														});
														setKnowledgeBaseListModalOpen(false);
													}}
													role="button"
												>
													<div className="flex items-center gap-3">
														<svg
															fill="#B18EED"
															width="24px"
															height="24px"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
															data-name="Layer 1"
														>
															<path d="M22,11A4,4,0,0,0,20,7.52,3,3,0,0,0,20,7a3,3,0,0,0-3-3l-.18,0A3,3,0,0,0,12,2.78,3,3,0,0,0,7.18,4L7,4A3,3,0,0,0,4,7a3,3,0,0,0,0,.52,4,4,0,0,0-.55,6.59A4,4,0,0,0,7,20l.18,0A3,3,0,0,0,12,21.22,3,3,0,0,0,16.82,20L17,20a4,4,0,0,0,3.5-5.89A4,4,0,0,0,22,11ZM11,8.55a4.72,4.72,0,0,0-.68-.32,1,1,0,0,0-.64,1.9A2,2,0,0,1,11,12v1.55a4.72,4.72,0,0,0-.68-.32,1,1,0,0,0-.64,1.9A2,2,0,0,1,11,17v2a1,1,0,0,1-1,1,1,1,0,0,1-.91-.6,4.07,4.07,0,0,0,.48-.33,1,1,0,1,0-1.28-1.54A2,2,0,0,1,7,18a2,2,0,0,1-2-2,2,2,0,0,1,.32-1.06A3.82,3.82,0,0,0,6,15a1,1,0,0,0,0-2,1.84,1.84,0,0,1-.69-.13A2,2,0,0,1,5,9.25a3.1,3.1,0,0,0,.46.35,1,1,0,1,0,1-1.74.9.9,0,0,1-.34-.33A.92.92,0,0,1,6,7,1,1,0,0,1,7,6a.76.76,0,0,1,.21,0,3.85,3.85,0,0,0,.19.47,1,1,0,0,0,1.37.37A1,1,0,0,0,9.13,5.5,1.06,1.06,0,0,1,9,5a1,1,0,0,1,2,0Zm7.69,4.32A1.84,1.84,0,0,1,18,13a1,1,0,0,0,0,2,3.82,3.82,0,0,0,.68-.06A2,2,0,0,1,19,16a2,2,0,0,1-2,2,2,2,0,0,1-1.29-.47,1,1,0,0,0-1.28,1.54,4.07,4.07,0,0,0,.48.33A1,1,0,0,1,14,20a1,1,0,0,1-1-1V17a2,2,0,0,1,1.32-1.87,1,1,0,0,0-.64-1.9,4.72,4.72,0,0,0-.68.32V12a2,2,0,0,1,1.32-1.87,1,1,0,0,0-.64-1.9,4.72,4.72,0,0,0-.68.32V5a1,1,0,0,1,2,0,1.06,1.06,0,0,1-.13.5,1,1,0,0,0,.36,1.37A1,1,0,0,0,16.6,6.5,3.85,3.85,0,0,0,16.79,6,.76.76,0,0,1,17,6a1,1,0,0,1,1,1,1,1,0,0,1-.17.55.9.9,0,0,1-.33.31,1,1,0,0,0,1,1.74A2.66,2.66,0,0,0,19,9.25a2,2,0,0,1-.27,3.62Z" />
														</svg>
														<div className="text-base font-semibold">{item.name}</div>
													</div>
												</div>
											))}
										</div>
									</InfiniteScroll>
								</div>
							</div>
						</Modal>
					</div>

					{/* desktop version */}
					<div className="sticky top-28 hidden h-[calc(100dvh-8rem)] w-[21rem] overflow-hidden min-[895px]:block">
						<Card
							className="h-full overflow-y-auto bg-input-bg dark:bg-dark-sidebar"
							styles={{ body: { padding: 0, height: '100%' } }}
							id="log-scrollable"
						>
							<div className="flex items-center justify-between gap-2 rounded-t-lg border-b bg-white p-3 dark:border-b-[#2d2b38] dark:bg-[#161422]">
								<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Knowledge Base</div>

								<div className="flex gap-2">
									<Button
										onClick={() => {
											setCreateKnowledgeBaseModalOpen(true);
										}}
										icon={<PlusOutlined />}
										className="h-9 px-3 text-[15px] font-semibold dark:bg-[#1b1827] dark:shadow-none"
									>
										Create
									</Button>
								</div>
							</div>

							<InfiniteScroll
								dataLength={kbList.length}
								next={
									kbList.length < count
										? () => {
												setPage((prev) => prev + 1);
											}
										: () => {}
								}
								hasMore={count > kbList.length}
								style={{
									overflow: 'hidden',
								}}
								loader={
									<div className="my-3 flex items-center justify-center">
										<Loader />
									</div>
								}
								scrollThreshold={0.9}
								scrollableTarget="log-scrollable"
							>
								<div className="flex flex-col gap-3.5 p-3">
									{kbList.map((item, i) => (
										<div
											key={i}
											className={cn('rounded-lg border bg-white px-2 py-2.5 transition-all duration-300 dark:bg-[#161422]', {
												'border-primary': searchParams.get('knowledge-base') === String(item.knowledge_base_id),
												'dark:border-[#14121f]': searchParams.get('knowledge-base') !== String(item.knowledge_base_id),
											})}
											onClick={() => {
												setSearchParams({
													'knowledge-base': String(item.knowledge_base_id),
												});
											}}
											role="button"
										>
											<div className="flex items-center gap-3">
												<svg
													fill="#B18EED"
													width="24px"
													height="24px"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
													data-name="Layer 1"
												>
													<path d="M22,11A4,4,0,0,0,20,7.52,3,3,0,0,0,20,7a3,3,0,0,0-3-3l-.18,0A3,3,0,0,0,12,2.78,3,3,0,0,0,7.18,4L7,4A3,3,0,0,0,4,7a3,3,0,0,0,0,.52,4,4,0,0,0-.55,6.59A4,4,0,0,0,7,20l.18,0A3,3,0,0,0,12,21.22,3,3,0,0,0,16.82,20L17,20a4,4,0,0,0,3.5-5.89A4,4,0,0,0,22,11ZM11,8.55a4.72,4.72,0,0,0-.68-.32,1,1,0,0,0-.64,1.9A2,2,0,0,1,11,12v1.55a4.72,4.72,0,0,0-.68-.32,1,1,0,0,0-.64,1.9A2,2,0,0,1,11,17v2a1,1,0,0,1-1,1,1,1,0,0,1-.91-.6,4.07,4.07,0,0,0,.48-.33,1,1,0,1,0-1.28-1.54A2,2,0,0,1,7,18a2,2,0,0,1-2-2,2,2,0,0,1,.32-1.06A3.82,3.82,0,0,0,6,15a1,1,0,0,0,0-2,1.84,1.84,0,0,1-.69-.13A2,2,0,0,1,5,9.25a3.1,3.1,0,0,0,.46.35,1,1,0,1,0,1-1.74.9.9,0,0,1-.34-.33A.92.92,0,0,1,6,7,1,1,0,0,1,7,6a.76.76,0,0,1,.21,0,3.85,3.85,0,0,0,.19.47,1,1,0,0,0,1.37.37A1,1,0,0,0,9.13,5.5,1.06,1.06,0,0,1,9,5a1,1,0,0,1,2,0Zm7.69,4.32A1.84,1.84,0,0,1,18,13a1,1,0,0,0,0,2,3.82,3.82,0,0,0,.68-.06A2,2,0,0,1,19,16a2,2,0,0,1-2,2,2,2,0,0,1-1.29-.47,1,1,0,0,0-1.28,1.54,4.07,4.07,0,0,0,.48.33A1,1,0,0,1,14,20a1,1,0,0,1-1-1V17a2,2,0,0,1,1.32-1.87,1,1,0,0,0-.64-1.9,4.72,4.72,0,0,0-.68.32V12a2,2,0,0,1,1.32-1.87,1,1,0,0,0-.64-1.9,4.72,4.72,0,0,0-.68.32V5a1,1,0,0,1,2,0,1.06,1.06,0,0,1-.13.5,1,1,0,0,0,.36,1.37A1,1,0,0,0,16.6,6.5,3.85,3.85,0,0,0,16.79,6,.76.76,0,0,1,17,6a1,1,0,0,1,1,1,1,1,0,0,1-.17.55.9.9,0,0,1-.33.31,1,1,0,0,0,1,1.74A2.66,2.66,0,0,0,19,9.25a2,2,0,0,1-.27,3.62Z" />
												</svg>
												<div className="text-base font-semibold">{item.name}</div>
											</div>
										</div>
									))}
								</div>
							</InfiniteScroll>
						</Card>
					</div>

					<div className="flex-1 pb-6 pt-2">
						<div className="h-full">
							{selectedKB ? (
								<Fragment>
									{kbDetailsLoading && (
										<div className="flex h-[calc(100dvh-20rem)] items-center justify-center md:h-full">
											<Loader />
										</div>
									)}

									{!kbDetailsLoading && kbDetails && (
										<div>
											<div className="flex flex-wrap items-start justify-between gap-5">
												<div>
													<h3 className="text-xl font-bold dark:text-white">{kbDetails.name}</h3>

													<div
														className="flex flex-wrap items-center gap-1.5 pt-1"
														role="button"
														onClick={() => {
															navigator.clipboard.writeText(kbDetails.knowledge_base_id);
															setCopied(true);
															toast.success('Knowledge Base ID copied to clipboard.');
														}}
													>
														<p className={'text-sm font-medium text-gray-500'}>ID: {kbDetails.knowledge_base_id}</p>

														<div className="text-sm text-gray-500">
															{copied ? <GoCheckCircleFill color="green" /> : <FaRegCopy />}
														</div>
													</div>

													<div className="flex flex-wrap items-center gap-1">
														<p className={'text-sm font-medium text-gray-500'}>
															Last Modified: {dayjs(kbDetails.updated_at).format('MM/DD/YYYY, HH:mm')}
														</p>
														<span className="text-gray-500">•</span>
														<div className={'text-sm font-medium capitalize'}>
															<span className="text-gray-500">Status:</span>{' '}
															<span
																className={cn('font-medium', {
																	'text-gray-500': kbDetails.status === 'pending',
																	'text-green-500': kbDetails.status === 'ready',
																})}
															>
																{kbDetails.status}
															</span>
														</div>
													</div>
												</div>

												<div className="flex flex-wrap items-center gap-3">
													{Object.keys(groupedSources.urlSources).length > 0 && (
														<Button
															type="primary"
															icon={
																<SyncOutlined
																	style={{
																		transform: 'rotate(90deg)',
																	}}
																/>
															}
															onClick={() => {
																// re-sync
																toast.success('On Development');
															}}
															className="h-10 font-medium"
														>
															Re-Sync
														</Button>
													)}

													<Button
														type="primary"
														icon={<EditOutlined />}
														onClick={() => {
															// open modal
															toast.success('On Development');
															// setUpdateKnowledgeBaseModalOpen(true);
														}}
														className="h-10 font-medium"
													>
														Edit
													</Button>

													<Button
														type="dashed"
														icon={<DeleteOutlined />}
														danger
														title="Delete Knowledge Base"
														onClick={() => {
															modal.confirm({
																title: 'Delete Knowledge Base',
																content: 'Are you sure you want to delete this knowledge base?',
																onOk: async () => {
																	await deleteKnowledgeBase(selectedKB);
																},
																centered: true,
																okButtonProps: { danger: true },
																okText: 'Delete',
															});
														}}
														className="h-10 font-medium"
													/>
												</div>
											</div>

											<Divider />

											{kbDetails.sources.length > 0 && (
												<div className="flex flex-col gap-2.5">
													{/* URL Groups */}
													{Object.entries(groupedSources.urlSources).map(([domain, sources]) => (
														<Card
															key={domain}
															styles={{ body: { padding: 16 } }}
															onClick={() => handleCollapse()}
															role="button"
														>
															<div className="flex items-center justify-between gap-3">
																<div className="flex items-center gap-3">
																	<div>{getIconsForType('url')}</div>
																	<div className="flex flex-col">
																		<div className="text-lg font-medium text-black-7 dark:text-gray-300">
																			{domain}
																		</div>
																		<div className="flex items-center gap-1 text-sm text-gray-500">
																			<div>{sources.length} pages</div>
																			<div>•</div>
																			<div>
																				Last Synced:{' '}
																				{dayjs(sources[0].updated_at).format('MM/DD/YYYY, HH:mm')}
																			</div>
																		</div>
																		<div className={'text-sm capitalize'}>
																			<span className="text-gray-500">Status: </span>
																			<span
																				className={cn('font-medium', {
																					'text-gray-500': getAggregateStatus(sources) === 'pending',
																					'text-red-500': getAggregateStatus(sources) === 'error',
																					'text-green-500': getAggregateStatus(sources) === 'ready',
																				})}
																			>
																				{getAggregateStatus(sources)}
																			</span>
																		</div>
																	</div>
																</div>
																{collapsed ? <GoChevronUp size={24} /> : <GoChevronDown size={24} />}
															</div>

															{collapsed && (
																<div className="mt-5 max-h-96 overflow-auto rounded-lg bg-gray-100 px-6 py-4 dark:bg-dark-sidebar dark:text-white">
																	{sources.map((source, i) => (
																		<div
																			key={i}
																			className="text-base font-medium text-gray-500"
																		>
																			{source.url}
																		</div>
																	))}
																</div>
															)}
														</Card>
													))}

													{/* Files and Text */}
													{groupedSources.otherSources.map((source) => {
														const fileExtension =
															source.type === 'file' ? source.name?.split('.').pop()?.toUpperCase() : undefined;

														return (
															<Card
																key={source.source_id}
																styles={{ body: { padding: 16 } }}
															>
																<div className="flex items-center justify-between gap-3">
																	<div className="flex items-center gap-3">
																		<div>{getIconsForType(source.type, fileExtension)}</div>
																		<div className="flex flex-col">
																			<div className="text-lg font-medium text-black-7 dark:text-gray-300">
																				{source.name || source.url}
																			</div>
																			<div className="flex flex-wrap items-center gap-1">
																				<p className="text-sm text-gray-500">
																					Last Modified:{' '}
																					{dayjs(source.updated_at).format('MM/DD/YYYY, HH:mm')}
																				</p>
																				<span className="text-gray-500">•</span>
																				<div className={'text-sm capitalize'}>
																					<span className="text-gray-500">Status: </span>
																					<span
																						className={cn('font-medium', {
																							'text-gray-500': source.status === 'pending',
																							'text-red-500': source.status === 'error',
																							'text-green-500': source.status === 'ready',
																						})}
																					>
																						{kbDetails.status}
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>

																	{downloadLoading && downloadLoadingID === source.source_id ? (
																		<Loader />
																	) : (
																		<HiOutlineDownload
																			size={22}
																			role="button"
																			onClick={async (e) => {
																				e.stopPropagation();

																				try {
																					setDownloadLoading(true);
																					setDownloadLoadingID(source.source_id);
																					if (source.type === 'file') {
																						const response = await axios.get(source.url as string, {
																							responseType: 'blob',
																							withCredentials: true,
																						});
																						const blob = new Blob([response.data]);
																						const downloadUrl = window.URL.createObjectURL(blob);
																						const link = document.createElement('a');
																						link.href = downloadUrl;
																						link.download = source.name as string;
																						document.body.appendChild(link);
																						link.click();
																						link.remove();
																						window.URL.revokeObjectURL(downloadUrl);
																					}
																					if (source.type === 'text') {
																						const blob = new Blob([source.url as string], {
																							type: 'text/plain',
																						});
																						const downloadUrl = window.URL.createObjectURL(blob);
																						const link = document.createElement('a');
																						link.href = downloadUrl;
																						link.download = `${source.name}.txt`;
																						document.body.appendChild(link);
																						link.click();
																						link.remove();
																						window.URL.revokeObjectURL(downloadUrl);
																					}
																				} catch (error) {
																					toast.error('Download failed');
																					console.error('Download failed:', error);
																				} finally {
																					setDownloadLoading(false);
																					setDownloadLoadingID(null);
																				}
																			}}
																		/>
																	)}
																</div>
															</Card>
														);
													})}
												</div>
											)}
										</div>
									)}
								</Fragment>
							) : (
								<Card className="flex h-full items-center justify-center">
									<div className="flex flex-col gap-2 text-center">
										<div className="text-base font-medium text-gray-600">Select a knowledge base to view details.</div>
									</div>
								</Card>
							)}
						</div>
					</div>
				</div>
			)}

			{/* create knowledge base modal */}
			<CreateKnowledgeBaseModal
				open={createKnowledgeBaseModalOpen}
				close={() => {
					setCreateKnowledgeBaseModalOpen(false);
				}}
				refresh={handleRefresh}
			/>

			{/* update knowledge base modal */}
			{kbDetails && (
				<UpdateKnowledgeBaseModal
					open={updateKnowledgeBaseModalOpen}
					close={() => {
						setUpdateKnowledgeBaseModalOpen(false);
					}}
					knowledgeBase={kbDetails}
				/>
			)}
		</AppLayout>
	);
};

export default KnowledgeBase;
