import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';

const isProd = import.meta.env.PROD;

Sentry.init({
	dsn: 'https://447b24ccec2b77e40e880fe78de391b9@o4507820294406144.ingest.us.sentry.io/4507820296175616',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	environment: isProd ? 'production' : 'development',
	enabled: isProd,
	tracesSampleRate: isProd ? 0.2 : 1.0,
	tracePropagationTargets: ['localhost', /^https:\/\/api\.puretalk\.ai/, /^https:\/\/[\w-]+\.puretalk\.ai/],
	replaysSessionSampleRate: isProd ? 0.1 : 1.0,
	replaysOnErrorSampleRate: 1.0,
	beforeSend(event) {
		if (!isProd) {
			console.log('Sentry Event:', event);
		}
		return event;
	},
});

const rootElement = document.getElementById('root');

if (!rootElement) {
	throw new Error('Failed to find root element');
}

ReactDOM.createRoot(rootElement).render(
	<React.StrictMode>
		<Sentry.ErrorBoundary fallback={<div className="flex h-dvh items-center justify-center">An error has occurred!</div>}>
			<App />
		</Sentry.ErrorBoundary>
	</React.StrictMode>,
);
