import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Modal } from 'antd';
import { nanoid } from 'nanoid';
import { FC, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsLink45Deg } from 'react-icons/bs';
import { CiTextAlignCenter } from 'react-icons/ci';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { TKnowledgeBase } from '../../views/knowledge-base';
import AddTextModal from './add-text-modal';
import AddWebPageModal from './add-webpage-modal';
import { Document } from './create-knowledge-base-modal';
import DropdownLabel from './dropdown-label';
import { getIconsForType } from './utils';

type Props = {
	open: boolean;
	close: () => void;
	knowledgeBase: TKnowledgeBase;
};

const UpdateKnowledgeBaseModal: FC<Props> = (props) => {
	const [knowledgeBaseName, setKnowledgeBaseName] = useState('');
	const [documents, setDocuments] = useState<Document[]>([]);
	const [addTextModalOpen, setAddTextModalOpen] = useState(false);
	const [addWebPageModalOpen, setAddWebPageModalOpen] = useState(false);

	return (
		<Modal
			title="Update Knowledge Base"
			open={props.open}
			onCancel={props.close}
			centered
			okButtonProps={{
				onClick: () => {
					console.log('Create Knowledge Base');
				},
				size: 'large',
				disabled: documents.length === 0 || knowledgeBaseName === '',
			}}
			cancelButtonProps={{
				size: 'large',
			}}
			okText="Save"
			width={800}
		>
			<div className="pt-2" />

			<div>
				<label
					htmlFor="name"
					className="text-base font-medium"
				>
					Knowledge Base Name
				</label>

				<div className="pt-2" />

				<Input
					id="name"
					className="w-full"
					size="large"
					value={knowledgeBaseName}
					onChange={(e) => setKnowledgeBaseName(e.target.value)}
					placeholder="Enter knowledge base name"
				/>
			</div>

			<div className="pt-4" />

			<div>
				<label
					htmlFor="documents"
					className="text-base font-medium"
				>
					Documents
				</label>

				<div className="pt-3" />

				{documents.length > 0 && (
					<div className="flex flex-wrap gap-2 pb-4">
						{documents.map((document) => (
							<div
								key={document.name}
								className="flex w-full items-center justify-between rounded-md border px-4 py-3 dark:border-dark-border"
							>
								<div className="flex items-center gap-2">
									<div>
										<div>{getIconsForType(document.type, document.fileType)}</div>
									</div>
									<div className="font-semibold">{document.name}</div>
								</div>
								<button
									onClick={() => {
										const newDocuments = documents.filter((doc) => doc !== document);
										setDocuments(newDocuments);
									}}
									className="text-gray-500 transition-colors hover:text-red-500"
								>
									<AiOutlineDelete size={20} />
								</button>
							</div>
						))}
					</div>
				)}

				<Dropdown
					menu={{
						items: [
							{
								label: (
									<DropdownLabel
										icon={<BsLink45Deg size={20} />}
										title="Add Web Pages"
										description="Crawl and sync your website"
									/>
								),
								key: 'url',
							},
							{
								label: (
									<DropdownLabel
										icon={<IoCloudUploadOutline size={20} />}
										title="Upload File"
										description="File size should be less than 100MB"
									/>
								),
								key: 'file',
							},
							{
								label: (
									<DropdownLabel
										icon={<CiTextAlignCenter size={20} />}
										title="Add Text"
										description="Add articles manually"
									/>
								),
								key: 'text',
							},
						],
						onClick: ({ key }) => {
							// handle url click
							if (key === 'url') {
								setAddWebPageModalOpen(true);
							}

							// handle file click
							if (key === 'file') {
								const input = document.createElement('input');
								input.type = 'file';
								input.multiple = false;
								input.accept = 'image/jpeg, image/jpg, image/png, application/pdf, .doc, .docx, .txt';
								input.onchange = (e) => {
									const file = (e.target as HTMLInputElement).files?.[0];
									if (file) {
										console.log(file);
										const fileExtension = file.name.split('.').pop()?.toUpperCase() || 'N/A';
										setDocuments((prev) => [
											...prev,
											{
												id: nanoid(),
												name: file.name,
												type: 'file',
												fileType: fileExtension,
											},
										]);
									}
								};
								input.click();
							}

							// handle text click
							if (key === 'text') {
								setAddTextModalOpen(true);
							}
						},
					}}
					overlayClassName="w-96 [&>ul]:!p-2"
					trigger={['click']}
				>
					<Button
						icon={<PlusOutlined />}
						size="large"
						className="font-medium"
					>
						Add
					</Button>
				</Dropdown>
			</div>

			{/* Add Text Modal */}
			<AddTextModal
				open={addTextModalOpen}
				close={() => setAddTextModalOpen(false)}
				setDocuments={setDocuments}
			/>

			{/* Add Web Page Modal */}
			<AddWebPageModal
				open={addWebPageModalOpen}
				close={() => setAddWebPageModalOpen(false)}
				setDocuments={setDocuments}
			/>
		</Modal>
	);
};

export default UpdateKnowledgeBaseModal;
