import { FC } from 'react';

type Props = {
	icon: React.ReactNode;
	title: string;
	description: string;
};

const DropdownLabel: FC<Props> = (props) => {
	return (
		<div className="flex items-center gap-2 py-1">
			<div className="rounded-full border p-3 shadow dark:border-dark-border">{props.icon}</div>
			<div>
				<div className="text-base font-semibold">{props.title}</div>
				<div className="text-gray-500">{props.description}</div>
			</div>
		</div>
	);
};

export default DropdownLabel;
