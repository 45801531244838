import { Form, Input, Modal } from 'antd';
import { nanoid } from 'nanoid';
import { Dispatch, FC, SetStateAction } from 'react';
import { Document } from './create-knowledge-base-modal';

type Props = {
	open: boolean;
	close: () => void;
	setDocuments: Dispatch<SetStateAction<Document[]>>;
};

type FieldType = {
	fileName: string;
	textContent: string;
};

const AddTextModal: FC<Props> = (props) => {
	const [form] = Form.useForm();

	return (
		<Modal
			title="Add Text"
			open={props.open}
			onCancel={props.close}
			centered
			okButtonProps={{
				onClick: () => form.submit(),
				size: 'large',
				disabled: false,
			}}
			cancelButtonProps={{
				size: 'large',
			}}
			okText="Save"
			destroyOnClose
			width={800}
		>
			<Form
				onFinish={(values: FieldType) => {
					props.setDocuments((prev: Document[]) => [
						...prev,
						{
							id: nanoid(),
							type: 'text',
							name: values.fileName,
							content: values.textContent,
						},
					]);
					form.resetFields();
					props.close();
				}}
				layout="vertical"
				form={form}
				requiredMark={false}
			>
				<Form.Item<FieldType>
					label={<div className="text-base font-medium">File Name</div>}
					name="fileName"
					rules={[
						{
							required: true,
							message: 'File Name is required',
						},
					]}
					hasFeedback
				>
					<Input
						size="large"
						placeholder="Enter File Name"
					/>
				</Form.Item>

				<Form.Item<FieldType>
					label={<div className="text-base font-medium">Text Content</div>}
					name="textContent"
					rules={[
						{
							required: true,
							message: 'Text Content is required',
						},
					]}
				>
					<Input.TextArea
						rows={6}
						placeholder="Enter Text Content"
						size="large"
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddTextModal;
