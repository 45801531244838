import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, theme } from 'antd';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import AuthenticatedRoute from './auth/authenticated-route';
import queryClient from './config/query';
import { AuthProvider } from './context/auth.context.tsx';
import { SidebarProvider } from './context/sidebar.context.tsx';
import { useThemeStore } from './store/theme';
import { colors } from './theme/colors';
import AIAssistants from './views/ai-assistants';
import APIKeys from './views/api-keys';
import Billing from './views/Billing';
import CalendarSync from './views/calendar-sync';
import CallLogs from './views/call-logs';
import Campaigns from './views/campaigns';
import ChatLogs from './views/chat-logs';
import ChatBot from './views/chatbot';
import CreateCampaign from './views/create-campaign';
import Dashboard from './views/Dashboard';
import ForgotPassword from './views/forgot-password.tsx';
import GithubAuthCallback from './views/github-auth-callback.tsx';
import KnowledgeBase from './views/knowledge-base.tsx';
import Login from './views/Login';
import NotFoundPage from './views/not-found-page.tsx';
import PhoneNumbers from './views/phone-numbers';
import Profile from './views/Profile';
import Redirect from './views/Redirect';
import Register from './views/Register';
import RuthConnect from './views/ruth-connect';
import RuthTemplates from './views/ruth-templates';
import VoiceLibrary from './views/voice-library';

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<Navigate
				to="/dashboard"
				replace
			/>
		),
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/register',
		element: <Register />,
	},
	{
		path: '/forgot-password',
		element: <ForgotPassword />,
	},
	{
		path: '/redirect',
		element: <Redirect />,
	},
	{
		path: '/github-auth-callback',
		element: <GithubAuthCallback />,
	},
	{
		path: '/chatbot/:id',
		element: <ChatBot />,
	},
	{
		element: <AuthenticatedRoute />,
		children: [
			{
				path: '/dashboard',
				element: <Dashboard />,
			},
			{
				path: '/ai-assistants',
				element: <AIAssistants />,
			},
			{
				path: '/knowledge-base',
				element: <KnowledgeBase />,
			},
			{
				path: '/ruth-templates',
				element: <RuthTemplates />,
			},
			{
				path: '/ruth-connect',
				element: <RuthConnect />,
			},
			{
				path: '/phone-numbers',
				element: <PhoneNumbers />,
			},
			{
				path: '/call-logs',
				element: <CallLogs />,
			},
			{
				path: '/chat-logs',
				element: <ChatLogs />,
			},
			{
				path: '/campaigns',
				element: <Campaigns />,
			},
			{
				path: '/create-campaign',
				element: <CreateCampaign />,
			},
			{
				path: '/calendar-sync',
				element: <CalendarSync />,
			},
			{
				path: '/voice-library',
				element: <VoiceLibrary />,
			},
			{
				path: '/billing',
				element: <Billing />,
			},
			{
				path: '/profile',
				element: <Profile />,
			},
			{
				path: '/api-keys',
				element: <APIKeys />,
			},
		],
	},
	{
		path: '*',
		element: <NotFoundPage />,
	},
]);

export default function App() {
	// controlling the dark mode
	const { is_dark_mode } = useThemeStore();

	useEffect(() => {
		if (is_dark_mode) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
	}, [is_dark_mode]);

	// Google OAuth Client ID
	const googleClientId: string =
		import.meta.env.VITE_ENV === 'production'
			? '949008854249-odv7uoqkfffe6apls71j0rr8mcippmr5.apps.googleusercontent.com'
			: '516320392011-7q6vvkd9ugdq7h9fq200nctjekrgnav3.apps.googleusercontent.com';

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: colors.primary,
					fontFamily: 'Satoshi, sans-serif',
					colorBgContainer: is_dark_mode ? '#161422' : '#ffffff',
					colorBgElevated: is_dark_mode ? '#161422' : '#ffffff',
				},
				components: {
					Button: {
						colorPrimary: colors.primary,
						defaultBg: is_dark_mode ? '#1b1827' : '#ffffff',
						colorBgContainerDisabled: is_dark_mode ? '#161422' : '#f5f5f5',
					},
					Badge: {
						colorPrimary: colors.primary,
						colorBgBase: colors.primary,
						colorPrimaryActive: colors.primary,
						dotSize: 12,
					},
					Segmented: {
						itemSelectedBg: colors.primary,
						itemSelectedColor: '#ffffff',
					},
					Carousel: {
						dotHeight: 12,
						dotActiveWidth: 12,
						dotWidth: 12,
						colorBgContainer: colors.primary,
					},
					Card: {
						colorBgContainer: is_dark_mode ? 'transparent' : '#ffffff',
					},
				},
				algorithm: is_dark_mode ? theme.darkAlgorithm : theme.defaultAlgorithm,
			}}
			wave={{
				disabled: true,
			}}
		>
			<QueryClientProvider client={queryClient}>
				<GoogleOAuthProvider clientId={googleClientId}>
					<AuthProvider>
						<SidebarProvider>
							<RouterProvider router={router} />
							<Toaster
								toastOptions={{
									className: 'font-medium bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100',
								}}
							/>
						</SidebarProvider>
					</AuthProvider>
				</GoogleOAuthProvider>
			</QueryClientProvider>
		</ConfigProvider>
	);
}
