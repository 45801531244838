import { nanoid } from 'nanoid';
import { ReactNode } from 'react';
import CalConnectUI from './cal-connect-ui';
import CalendlyConnectUI from './calendly-connect-ui';
import Upcoming from './upcoming-ui';

export type Filter = {
	label: string;
	value: string;
};

export type App = {
	uuid: string;
	image: string;
	vendor: string;
	app_name: string;
	title: string;
	content: string;
	categories: string[];
	connect_UI: ReactNode;
};

export const filters: Filter[] = [
	{
		label: 'All',
		value: 'all',
	},
	{
		label: 'Calendars',
		value: 'calendars',
	},
	{
		label: 'Automation',
		value: 'automation',
	},
	{
		label: 'CRM',
		value: 'crm',
	},
	{
		label: 'Analytics',
		value: 'analytics',
	},
	{
		label: 'Messaging',
		value: 'messaging',
	},
	{
		label: 'Conferencing',
		value: 'conferencing',
	},
];

export const mostPopular: App[] = [
	{
		image: '/images/ruth-connect/calendly.svg',
		vendor: 'Calendly',
		title: 'Calendly',
		content: 'Connect your Calendly with Ruth® Connect for a unified scheduling experience across both platforms.',
		categories: ['analytics', 'automation', 'crm'],
		app_name: 'calendly',
		connect_UI: <CalendlyConnectUI />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/apple_calendar.png',
		vendor: 'Apple',
		title: 'iCloud Calendar',
		content: 'Sync your iCloud calendar with Ruth® Connect to seamlessly manage and update your events, ensuring.',
		categories: ['calendars', 'automation', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/google_calendar.png',
		vendor: 'Google',
		title: 'Google Calendar',
		content: 'Integrate your Google Calendar with Ruth® Connect for a streamlined management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/teams.png',
		vendor: 'Microsoft',
		title: 'Teams',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'automation', 'analytics'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/outlook.png',
		vendor: 'Microsoft',
		title: 'Outlook Calendar',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'crm', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
];

export const apps: App[] = [
	{
		image: '/images/ruth-connect/calendly.svg',
		vendor: 'Calendly',
		title: 'Calendly',
		content: 'Connect your Calendly with Ruth® Connect for a unified scheduling experience across both platforms.',
		categories: ['analytics', 'automation', 'crm', 'messaging', 'conferencing', 'calendars'],
		app_name: 'calendly',
		connect_UI: <CalendlyConnectUI />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/cal-logo.svg',
		vendor: 'Cal.com',
		title: 'Cal.com',
		content: 'Connect your Cal.com with Ruth® Connect for a unified scheduling experience across both platforms.',
		categories: ['analytics', 'automation', 'crm', 'messaging', 'conferencing', 'calendars'],
		app_name: 'cal.com',
		connect_UI: <CalConnectUI />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/apple_calendar.png',
		vendor: 'Apple',
		title: 'iCloud Calendar',
		content: 'Sync your iCloud calendar with Ruth® Connect to seamlessly manage and update your events, ensuring.',
		categories: ['calendars', 'automation', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/google_calendar.png',
		vendor: 'Google',
		title: 'Google Calendar',
		content: 'Integrate your Google Calendar with Ruth® Connect for a streamlined management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/outlook.png',
		vendor: 'Microsoft',
		title: 'Outlook Calendar',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'crm', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/teams.png',
		vendor: 'Microsoft',
		title: 'Teams',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'automation', 'analytics'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/google-drive.png',
		vendor: 'Google',
		title: 'Google Drive',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},

	{
		image: '/images/ruth-connect/yahoo.png',
		vendor: 'Yahoo',
		title: 'Yahoo Calendar',
		content: 'Sync your iCloud calendar with Ruth® Connect to seamlessly manage and update your events, ensuring.',
		categories: ['calendars', 'messaging', 'automation'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/jira.png',
		vendor: 'Jira',
		title: 'Jira Board',
		content: 'Integrate your Google Calendar with Ruth® Connect for a streamlined management of your events across both platforms.',
		categories: ['calendars', 'analytics', 'crm'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/zapier.svg',
		vendor: 'Zapier',
		title: 'Zapier Automation',
		content: 'Connect your Calendly with Ruth® Connect for a unified scheduling experience across both platforms.',
		categories: ['calendars', 'automation', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/clickup.webp',
		vendor: 'ClickUp',
		title: 'ClickUp Task Management',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'conferencing', 'analytics'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
	{
		image: '/images/ruth-connect/hubspot.webp',
		vendor: 'HubSpot',
		title: 'HubSpot Calendar',
		content: 'Link your Outlook calendar with Ruth® Connect for a consolidated view and management of your events across both platforms.',
		categories: ['calendars', 'crm', 'messaging'],
		app_name: '',
		connect_UI: <Upcoming />,
		uuid: nanoid(),
	},
];
