import type { CheckboxProps } from 'antd';
import { Checkbox, Input, Modal } from 'antd';
import { nanoid } from 'nanoid';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import axios from '../../config/axios';
import Loader from '../Loader';
import { Document } from './create-knowledge-base-modal';

type Props = {
	open: boolean;
	close: () => void;
	setDocuments: Dispatch<SetStateAction<Document[]>>;
};

const AddWebPageModal: FC<Props> = (props) => {
	const [url, setUrl] = useState('');
	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [plainOptions, setPlainOptions] = useState<string[]>([]);
	const [checkedList, setCheckedList] = useState<string[]>([]);

	const checkAll = plainOptions.length === checkedList.length;
	const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

	const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
		setCheckedList(e.target.checked ? plainOptions : []);
	};

	const getBaseUrl = (urlString: string) => {
		try {
			const url = new URL(urlString);
			return `${url.protocol}//${url.hostname}`;
		} catch {
			return urlString;
		}
	};

	const parseSitemap = async (urlString: string) => {
		const controller = new AbortController();
		const timeoutId = setTimeout(() => controller.abort(), 10000); // 10s timeout

		try {
			setLoading(true);
			const baseUrl = getBaseUrl(urlString);

			const { data } = await axios.get(`/knowledge-base/sitemap?url=${baseUrl}`, {
				withCredentials: true,
				signal: controller.signal,
			});

			if (!data?.results || !Array.isArray(data.results) || data.results.length === 0) {
				// If no results, use the original URL as fallback
				setPlainOptions([urlString]);
			} else {
				setPlainOptions(data.results);
			}
		} catch (error) {
			console.error('Failed to parse sitemap:', error);
			// Use original URL as fallback on error
			setPlainOptions([urlString]);
		} finally {
			clearTimeout(timeoutId);
			setLoading(false);
		}
	};

	return (
		<Modal
			title={step === 1 ? 'Add Web Page' : 'Select Site Maps'}
			open={props.open}
			onCancel={() => {
				if (step === 1) {
					props.close();
				}
				if (step === 2) {
					setStep(1);
				}
			}}
			centered
			afterClose={() => {
				setStep(1);
				setUrl('');
				setCheckedList([]);
			}}
			okButtonProps={{
				onClick: () => {
					if (step === 1) {
						setStep(2);
						parseSitemap(url);
					}

					if (step === 2 && checkedList.length > 0) {
						try {
							// Remove duplicates from checkedList first
							const uniqueUrls = [...new Set(checkedList)];

							// Group unique URLs by domain
							const urlGroups = uniqueUrls.reduce(
								(acc, url) => {
									const domain = new URL(url).hostname;
									if (!acc[domain]) {
										acc[domain] = new Set();
									}
									acc[domain].add(url);
									return acc;
								},
								{} as Record<string, Set<string>>,
							);

							// Create documents for each domain group
							const newDocuments = Object.entries(urlGroups).map(([domain, urls]) => ({
								id: nanoid(),
								name: domain,
								type: 'url' as const,
								content: Array.from(urls),
							}));

							props.setDocuments((prev) => [...prev, ...newDocuments]);
						} catch (error) {
							console.error('Invalid URL format:', error);
						} finally {
							props.close();
						}
					}
				},
				size: 'large',
				disabled: step === 1 && !url,
			}}
			cancelButtonProps={{
				size: 'large',
				onClick: () => {
					if (step === 2) setStep(1);
					if (step === 1) props.close();
				},
			}}
			okText={step === 1 ? 'Next' : 'Save'}
			destroyOnClose
			width={800}
		>
			<div className="pt-2" />

			{step === 1 && (
				<div>
					<label
						htmlFor="name"
						className="text-base font-medium"
					>
						Web Page URL
					</label>

					<div className="pt-2" />

					<Input
						id="name"
						className="w-full"
						size="large"
						value={url}
						onChange={(e) => setUrl(e.target.value)}
						placeholder="Enter web page URL"
					/>
				</div>
			)}

			{step === 2 && (
				<div>
					{loading && (
						<div className="flex min-h-60 items-center justify-center">
							<Loader />
						</div>
					)}

					{!loading && (
						<div>
							<div className="flex items-center justify-between">
								<Checkbox
									indeterminate={indeterminate}
									onChange={onCheckAllChange}
									checked={checkAll}
									className="font-semibold"
								>
									Select all ({plainOptions.length})
								</Checkbox>

								<div className="font-semibold text-primary">Selected ({checkedList.length})</div>
							</div>

							<div className="mt-4 flex max-h-96 flex-col gap-2 overflow-auto">
								{plainOptions.map((option) => (
									<Checkbox
										key={option}
										checked={checkedList.includes(option)}
										onChange={(e) => {
											if (e.target.checked) {
												setCheckedList([...checkedList, option]);
											} else {
												setCheckedList(checkedList.filter((item) => item !== option));
											}
										}}
									>
										{option}
									</Checkbox>
								))}
							</div>
						</div>
					)}
				</div>
			)}
		</Modal>
	);
};

export default AddWebPageModal;
