import { nanoid } from 'nanoid';
import { create } from 'zustand';

type FunctionData = {
	name: string;
	description: string;
	[key: string]: string | unknown;
};

export type Tool = {
	id: string;
	type: string;
	functionId: string;
	function: FunctionData;
};

type ToolState = {
	tools: Tool[];
	setTools: (tools: Tool[]) => void;
	addTool: (tool: Tool) => void;
	updateTool: (id: string, updatedTool: Partial<Tool>) => void;
	deleteTool: (id: string) => void;
};

export const useToolsStore = create<ToolState>((set) => ({
	tools: [],
	setTools: (tools) => set(() => ({ tools })),
	addTool: (tool) =>
		set((state) => {
			const isDuplicate = state.tools.some((t) => t.functionId === tool.functionId);
			if (!isDuplicate) {
				return { tools: [...state.tools, { ...tool, id: nanoid() }] };
			}
			return state;
		}),
	updateTool: (id, updatedTool) =>
		set((state) => ({
			tools: state.tools.map((tool) => (tool.id === id ? { ...tool, ...updatedTool } : tool)),
		})),
	deleteTool: (id) => set((state) => ({ tools: state.tools.filter((tool) => tool.id !== id) })),
}));
