import { useQuery } from '@tanstack/react-query';
import { Button, Card, Input, Modal } from 'antd';
import { nanoid } from 'nanoid';
import { FC, Fragment } from 'react';
import { GrRevert } from 'react-icons/gr';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoChevronForwardSharp } from 'react-icons/io5';
import { SiGithubactions } from 'react-icons/si';
import { TbBoltOff } from 'react-icons/tb';
import { VscTools } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';
import { cn } from '../../config/cn';
import { Tool } from '../../store/tools';
import { colors } from '../../theme/colors';
import Loader from '../Loader';
import { Integration } from '../ruth-connect/ruth-connect-types';
import FormLabel from './form-label';
import { Tab, tabs } from './tabs-data';

type ActionsFormProps = {
	setActiveTab: (value: Tab) => void;
	tools: Tool[];
	setTools: (value: Tool[]) => void;
	selectedTools: string[];
	setSelectedTools: (value: string[]) => void;
};

const getInitialToolsByAppName = (appName: string) => {
	switch (appName) {
		case 'calendly':
			return [
				{
					type: 'function',
					functionId: 'calendly_get_user_busy_times',
					id: nanoid(),
					function: {
						name: 'check_calendar_availability',
						description:
							'Get get busy time from the calendar. This function will return the busy time from the calendar for the given date range. Date range should not exceed 7 days.',
						parameters: {
							type: 'object',
							properties: {
								start_time: {
									type: 'string',
									description: 'Availability check start date in YYYY-MM-DD format, e.g. 2024-06-18',
								},
								end_time: {
									type: 'string',
									description: 'Availability check end date in YYYY-MM-DD format, e.g. 2024-06-23',
								},
							},
							required: ['start_time', 'end_time'],
						},
					},
				},
				{
					type: 'function',
					functionId: 'calendly_create_calendar_event',
					id: nanoid(),
					function: {
						name: 'book_appointment',
						description:
							'Book an appointment in the calendar. This function will book an appointment in the calendar for the given date and time.',
						parameters: {
							type: 'object',
							properties: {
								calendar_event_name: {
									type: 'string',
									description: 'Name of the calendar event, e.g. "Meeting with John"',
								},
								duration: {
									type: 'number',
									description: 'Duration of the calendar event in minutes, e.g. 60',
								},
								start_date_time: {
									type: 'string',
									description: 'Start date and time of the calendar event in YYYY-MM-DDTHH:MM format, e.g. 2024-06-18T14:00',
								},
								end_date_time: {
									type: 'string',
									description: 'End date and time of the calendar event in YYYY-MM-DDTHH:MM format, e.g. 2024-06-18T15:00',
								},
							},
							required: ['calendar_event_name', 'duration', 'start_date_time', 'end_date_time'],
						},
					},
				},
			];

		case 'cal.com':
			return [
				{
					type: 'function',
					functionId: 'cal_dot_com_book_on_calendar',
					id: nanoid(),
					function: {
						name: 'cal_dot_com_book_on_calendar',
						description: 'Create a booking on Cal.com calendar',
						parameters: {
							type: 'object',
							properties: {
								name: {
									type: 'string',
									description: 'Name of the customer. ask customer to provide name if you do not have it already',
								},
								email: {
									type: 'string',
									description: 'Email of the customer. ask user to provide email if you do not have it already',
								},
								timezone: {
									type: 'string',
									description: 'Timezone of the ai assistant',
								},
								start: {
									type: 'string',
									description: 'Start time of the booking. should be always in future. example: 2024-09-25T20:45:00',
								},
								puretalkCallId: {
									type: 'string',
									description: 'Puretalk Call id of the current call',
								},
							},
							required: ['name', 'email', 'timezone', 'start', 'puretalkCallId'],
						},
					},
				},
			];

		case 'End Call':
			return [
				{
					type: 'function',
					app_name: 'End Call',
					friendly_app_name: 'End Call',
					functionId: 'end_call',
					logo: '/images/end_call.svg',
					id: nanoid(),
					function: {
						name: 'end_call',
						description: 'End the call when user has to leave (like says bye) or you are instructed to do so.',
						parameters: {
							type: 'object',
							properties: {
								provider_call_id: {
									type: 'string',
									description: 'Provider call id of the current call',
								},
								protocol: {
									type: 'string',
									description: 'Protocol of the call',
									enum: ['telnyx', 'twilio', 'web'],
								},
							},
							required: ['provider_call_id', 'protocol'],
						},
					},
				},
			];

		case 'Call Transfer':
			return [
				{
					type: 'function',
					app_name: 'Call Transfer',
					friendly_app_name: 'Call Transfer',
					functionId: 'transfer_call',
					logo: '/images/transfer_call.svg',
					id: nanoid(),
					function: {
						name: 'transfer_call',
						description: 'When user is angry or requests a human agent, transfer the call to a human.',
						transfer_to: `+1234567890`,
						parameters: {
							type: 'object',
							properties: {
								transfer_to: {
									type: 'string',
									description: 'Phone number of the human agent to transfer the call to.',
								},
							},
							required: ['transfer_to'],
						},
					},
				},
			];

		default:
			return [];
	}
};

const generalTools = [
	{
		type: 'function',
		app_name: 'End Call',
		friendly_app_name: 'End Call',
		functionId: 'end_call',
		logo: '/images/end_call.svg',
		id: nanoid(),
		function: {
			name: 'end_call',
			description: 'End the call when user has to leave (like says bye) or you are instructed to do so.',
			parameters: {
				type: 'object',
				properties: {
					provider_call_id: {
						type: 'string',
						description: 'Provider call id of the current call',
					},
					protocol: {
						type: 'string',
						description: 'Protocol of the call',
						enum: ['telnyx', 'twilio', 'web'],
					},
				},
				required: ['provider_call_id', 'protocol'],
			},
		},
	},
	{
		type: 'function',
		app_name: 'Call Transfer',
		friendly_app_name: 'Call Transfer',
		functionId: 'transfer_call',
		logo: '/images/transfer_call.svg',
		id: nanoid(),
		function: {
			name: 'transfer_call',
			description: 'When user is angry or requests a human agent, transfer the call to a human / +1234567890',
			transfer_to: `+1234567890`,
			parameters: {
				type: 'object',
				properties: {
					provider_call_id: {
						type: 'string',
						description: 'Provider call id of the current call',
					},
					to_number: {
						type: 'string',
						description: 'The phone number where the call will be transferred to. Must include + and country code',
					},
					from_number: {
						type: 'string',
						description: 'The phone number from where the call will be transferred. It is in the prompt',
					},
					protocol: {
						type: 'string',
						description: 'Protocol of the call',
						enum: ['telnyx', 'twilio', 'web'],
					},
				},
				required: ['provider_call_id', 'to_number', 'from_number', 'protocol'],
			},
		},
	},
];

const ActionsForm: FC<ActionsFormProps> = (props) => {
	const [modal, contextHolder] = Modal.useModal();
	// get the apps that is connected by the user
	const { data, isLoading } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
		queryFn: async () => {
			const { data } = await axios.get('/ruth-connect/integrations', { withCredentials: true });

			return data.results;
		},
	});

	return (
		<Fragment>
			{contextHolder}
			<div>
				<FormLabel
					label="General Tools"
					icon={<VscTools size={20} />}
				/>

				<div className="pt-1" />

				<p className="text-sm font-semibold text-gray-500">Select some general tools you'd like your agent to have access to.</p>

				<div className="pt-3" />

				<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
					{generalTools.map((tool, i) => (
						<div
							key={i}
							className="flex items-center justify-between gap-2 rounded-xl border px-3 py-2 dark:border-[#2d2b38]"
							role="button"
							onClick={() => {
								// toggle the selected tool
								if (props.selectedTools && props.selectedTools.includes(tool.app_name)) {
									// Remove the tool from the selected tools
									props.setSelectedTools(props.selectedTools.filter((selectedTool) => selectedTool !== tool.app_name));
									props.setTools(props.tools.filter((selectedTool) => selectedTool.function.name !== tool.function.name));
								} else {
									// Add the tool to the selected tools
									props.setSelectedTools([...props.selectedTools, tool.app_name]);
									props.setTools([...props.tools, ...getInitialToolsByAppName(tool.app_name)]);
								}
							}}
						>
							<div className="flex items-center gap-2">
								<img
									src={tool.logo}
									alt="tool-logo"
									className="h-6"
								/>

								<div className="text-lg font-medium capitalize text-gray-700 dark:text-gray-300">{tool.friendly_app_name}</div>
							</div>

							{props.selectedTools && props.selectedTools.includes(tool.app_name) ? (
								<div>
									<IoIosCheckmarkCircle
										color={colors.primary}
										size={20}
									/>
								</div>
							) : null}
						</div>
					))}
				</div>

				{props.selectedTools && props.selectedTools.includes('End Call') ? (
					<Card
						styles={{ body: { padding: 16 } }}
						className="mt-3"
					>
						<h2 className="text-lg font-semibold">End Call</h2>

						<div className="mt-3">
							<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Description</div>
							<Input
								defaultValue={`End the call when user has to leave (like says bye) or you are instructed to do so.`}
								size="large"
								onChange={(e) => {
									props.setTools(
										props.tools.map((tool) => {
											if (tool.function.name === 'end_call') {
												return {
													...tool,
													function: {
														...tool.function,
														description: e.target.value,
													},
												};
											}

											return tool;
										}),
									);
								}}
								className="bg-input-bg dark:bg-[#1b1827]"
							/>
						</div>
					</Card>
				) : null}

				{props.selectedTools && props.selectedTools.includes('Call Transfer') ? (
					<Card
						styles={{ body: { padding: 16 } }}
						className="mt-3"
					>
						<h2 className="text-lg font-semibold">Call Transfer</h2>

						<div className="mt-3">
							<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Description</div>
							<Input
								defaultValue={`When user is angry or requests a human agent, transfer the call to a human.`}
								size="large"
								onKeyDown={(e) => {
									if (e.key === '/') {
										e.preventDefault();
									}
								}}
								onChange={(e) => {
									props.setTools(
										props.tools.map((tool) => {
											if (tool.function.name === 'transfer_call') {
												return {
													...tool,
													function: {
														...tool.function,
														description: e.target.value,
													},
												};
											}

											return tool;
										}),
									);
								}}
								className="bg-input-bg dark:bg-[#1b1827]"
							/>
						</div>

						<div className="mt-3">
							<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Transfer To</div>
							<Input
								defaultValue={`+1234567890`}
								size="large"
								onChange={(e) => {
									props.setTools(
										props.tools.map((tool) => {
											if (tool.function.name === 'transfer_call') {
												return {
													...tool,
													function: {
														...tool.function,
														transfer_to: e.target.value,
													},
												};
											}

											return tool;
										}),
									);
								}}
								className="bg-input-bg dark:bg-[#1b1827]"
							/>
							<small>Phone number must include + and country code, e.g. +1234567890</small>
						</div>
					</Card>
				) : null}
			</div>

			<div className="pt-6" />

			<div>
				<FormLabel
					label="RUTH® Connect"
					icon={<SiGithubactions size={20} />}
				/>

				<div className="pt-1" />

				<p className="text-sm font-semibold text-gray-500">Select the action capabilities {`you'd`} like your agent to have access to.</p>

				<div className="pt-3" />

				{isLoading && (
					<div className="flex h-32 items-center justify-center">
						<Loader />
					</div>
				)}

				{data && data.length === 0 && (
					<div className="flex flex-col items-center justify-center gap-2 rounded-lg border border-dotted px-10 py-5 text-center dark:border-[#2d2b38]">
						<div>
							<TbBoltOff size={25} />
						</div>

						<h4 className="font-semibold">You don't have any connected app yet.</h4>

						<div className="text-sm text-gray-600 dark:text-gray-300">
							RUTH® Connect are functions that instruct agents what other tools and data sources to interact with. You can create one
							in the {`RUTH® Connect`} tab or{' '}
							<Link
								to={'/ruth-connect'}
								className="text-primary"
								target="_blank"
							>
								click here.
							</Link>
						</div>
					</div>
				)}

				{data && data.length > 0 && (
					<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
						{data.map((tool, i) => (
							<div
								key={i}
								className="flex items-center justify-between gap-2 rounded-xl border px-3 py-2 dark:border-[#2d2b38]"
								role="button"
								onClick={() => {
									// toggle the selected tool
									if (props.selectedTools && props.selectedTools.includes(tool.app_name)) {
										// Remove the tool from the selected tools
										props.setSelectedTools(props.selectedTools.filter((selectedTool) => selectedTool !== tool.app_name));
										props.setTools(props.tools.filter((selectedTool) => selectedTool.function.name !== tool.app_name));
									} else {
										// Add the tool to the selected tools
										props.setSelectedTools([...props.selectedTools, tool.app_name]);
										props.setTools([...props.tools, ...getInitialToolsByAppName(tool.app_name)]);
									}
								}}
							>
								<div className="flex items-center gap-2">
									<img
										src={tool.logo}
										alt="tool-logo"
										className={cn('h-8', {
											'h-4 dark:brightness-0 dark:invert': ['cal.com'].includes(tool.app_name),
										})}
									/>

									<div className="text-lg font-medium capitalize text-gray-700 dark:text-gray-300">{tool.friendly_app_name}</div>
								</div>

								{props.selectedTools && props.selectedTools.includes(tool.app_name) ? (
									<div>
										<IoIosCheckmarkCircle
											color={colors.primary}
											size={20}
										/>
									</div>
								) : null}
							</div>
						))}
					</div>
				)}
			</div>

			<div className="pt-6" />

			<div className="grid grid-cols-2 gap-5">
				<Button
					size="large"
					type="dashed"
					block
					icon={<GrRevert />}
					onClick={() => {
						props.setActiveTab(tabs[2]);
					}}
				>
					Back
				</Button>

				<Button
					size="large"
					type="primary"
					block
					icon={<IoChevronForwardSharp />}
					iconPosition="end"
					onClick={() => {
						// if call transfer is selected and transfer_to is not filled, return

						if (props.selectedTools.includes('Call Transfer')) {
							const transferCallTool = props.tools.find((tool) => tool.function.name === 'transfer_call');

							if (!transferCallTool?.function.transfer_to) {
								return modal.error({
									title: 'Error',
									content: 'Please fill the phone number to transfer the call to.',
									centered: true,
								});
							}
						}

						props.setActiveTab(tabs[4]);
					}}
				>
					Next
				</Button>
			</div>
		</Fragment>
	);
};

export default ActionsForm;
