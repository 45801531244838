import { nanoid } from 'nanoid';
import { ReactNode } from 'react';
import { Tool } from '../../../store/tools';
import CalBookOnCalender from '../cal/cal_dot_com_book_on_calendar';
import CheckCalendarAvailability from '../calendly/check-calendar-availability';
import CreateCalendarEvent from '../calendly/create-calendar-event';
import CallTransfer from '../generic/call-transfer';
import EndCall from '../generic/end-call';

type AllToolType = {
	appName: string;
	formUI: (props: { tool: Tool }) => ReactNode;
	payload: Tool;
};

export const allTools: AllToolType[] = [
	{
		appName: 'Calendly Availability',
		formUI: ({ tool }: { tool: Tool }) => <CheckCalendarAvailability tool={tool} />,
		payload: {
			id: nanoid(),
			type: 'function',
			functionId: 'calendly_get_user_busy_times',
			function: {
				name: 'check_calendar_availability',
				description:
					'Get get busy time from the calendar. This function will return the busy time from the calendar for the given date range. Date range should not exceed 7 days.',
				parameters: {
					type: 'object',
					properties: {
						start_time: {
							type: 'string',
							description: 'Availability check start date in YYYY-MM-DD format, e.g. 2024-06-18',
						},
						end_time: {
							type: 'string',
							description: 'Availability check end date in YYYY-MM-DD format, e.g. 2024-06-23',
						},
					},
					required: ['start_time', 'end_time'],
				},
			},
		},
	},
	{
		appName: 'Calendly Booking',
		formUI: ({ tool }: { tool: Tool }) => <CreateCalendarEvent tool={tool} />,
		payload: {
			id: nanoid(),
			type: 'function',
			functionId: 'calendly_create_calendar_event',
			function: {
				name: 'book_appointment',
				description:
					'Book an appointment in the calendar. This function will book an appointment in the calendar for the given date and time.',
				parameters: {
					type: 'object',
					properties: {
						calendar_event_name: {
							type: 'string',
							description: 'Name of the calendar event, e.g. "Meeting with John"',
						},
						duration: {
							type: 'number',
							description: 'Duration of the calendar event in minutes, e.g. 60',
						},
						start_date_time: {
							type: 'string',
							description: 'Start date and time of the calendar event in YYYY-MM-DDTHH:MM format, e.g. 2024-06-18T14:00',
						},
						end_date_time: {
							type: 'string',
							description: 'End date and time of the calendar event in YYYY-MM-DDTHH:MM format, e.g. 2024-06-18T15:00',
						},
					},
					required: ['calendar_event_name', 'duration', 'start_date_time', 'end_date_time'],
				},
			},
		},
	},
	{
		appName: 'End Call',
		formUI: ({ tool }: { tool: Tool }) => <EndCall tool={tool} />,
		payload: {
			id: nanoid(),
			type: 'function',
			functionId: 'end_call',
			function: {
				name: 'end_call',
				description: 'End the call when user has to leave (like says bye) or you are instructed to do so.',
				parameters: {
					type: 'object',
					properties: {
						provider_call_id: {
							type: 'string',
							description: 'Provider call id of the current call',
						},
						protocol: {
							type: 'string',
							description: 'Protocol of the call',
							enum: ['telnyx', 'twilio', 'web'],
						},
					},
					required: ['provider_call_id', 'protocol'],
				},
			},
		},
	},
	{
		appName: 'Call Transfer',
		formUI: ({ tool }: { tool: Tool }) => <CallTransfer tool={tool} />,
		payload: {
			id: nanoid(),
			type: 'function',
			functionId: 'transfer_call',
			function: {
				name: 'transfer_call',
				description: 'When user is angry or requests a human agent, transfer the call to a human / +1234567890',
				transfer_to: '+1234567890',
				parameters: {
					type: 'object',
					properties: {
						provider_call_id: {
							type: 'string',
							description: 'Provider call id of the current call',
						},
						to_number: {
							type: 'string',
							description: 'The phone number where the call will be transferred to. Must include + and country code',
						},
						from_number: {
							type: 'string',
							description: 'The phone number from where the call will be transferred. It is in the prompt',
						},
						protocol: {
							type: 'string',
							description: 'Protocol of the call',
							enum: ['telnyx', 'twilio', 'web'],
						},
					},
					required: ['provider_call_id', 'to_number', 'from_number', 'protocol'],
				},
			},
		},
	},
	{
		appName: 'Cal.com Booking',
		formUI: ({ tool }: { tool: Tool }) => <CalBookOnCalender tool={tool} />,
		payload: {
			id: nanoid(),
			type: 'function',
			functionId: 'cal_dot_com_book_on_calendar',
			function: {
				name: 'cal_dot_com_book_on_calendar',
				description: 'Create a booking on Cal.com calendar',
				parameters: {
					type: 'object',
					properties: {
						name: {
							type: 'string',
							description: 'Name of the customer. ask customer to provide name if you do not have it already',
						},
						email: {
							type: 'string',
							description: 'Email of the customer. ask user to provide email if you do not have it already',
						},
						timezone: {
							type: 'string',
							description: 'Timezone of the ai assistant',
						},
						start: {
							type: 'string',
							description: 'Start time of the booking. should be always in future. example: 2024-09-25T20:45:00',
						},
						puretalkCallId: {
							type: 'string',
							description: 'Puretalk Call id of the current call',
						},
					},
					required: ['name', 'email', 'timezone', 'start', 'puretalkCallId'],
				},
			},
		},
	},
];
