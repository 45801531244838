export const getIconsForType = (type: string, fileType = 'IMG') => {
	switch (type) {
		case 'url':
			return (
				<svg
					width={32}
					height={32}
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M24.2 31.4H7.8a4.2 4.2 0 0 1-4.2-4.2V4.8A4.2 4.2 0 0 1 7.8.6h8.671a4.2 4.2 0 0 1 2.947 1.207l7.729 7.61A4.2 4.2 0 0 1 28.4 12.41V27.2a4.2 4.2 0 0 1-4.2 4.2Z"
						fill="#fff"
						stroke="#CACFD8"
						strokeWidth={1.2}
					/>
					<path
						d="M18 1v6.8a3.2 3.2 0 0 0 3.2 3.2H28"
						stroke="#CACFD8"
						strokeWidth={1.2}
					/>
					<path
						d="M0 17a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3z"
						fill="#F6B51E"
					/>
					<path
						d="M7.666 17.6h1.159v4.181q0 .688-.325 1.21-.323.522-.906.815-.585.29-1.366.29-.784 0-1.369-.29a2.2 2.2 0 0 1-.906-.815 2.26 2.26 0 0 1-.322-1.21V17.6h1.16v4.084q0 .4.175.713.177.312.5.49.321.175.762.175.44 0 .763-.175.324-.177.5-.49.175-.312.175-.713zM10.26 24v-6.4h2.4q.737 0 1.238.256.503.256.759.719.26.459.26 1.072 0 .616-.263 1.069-.26.45-.766.697-.506.243-1.243.243h-1.71v-.962h1.553q.432 0 .706-.119a.84.84 0 0 0 .407-.353 1.14 1.14 0 0 0 .134-.575q0-.34-.134-.581a.86.86 0 0 0-.41-.37q-.275-.127-.709-.127H11.42V24zm3.306-2.9 1.585 2.9h-1.294l-1.556-2.9zM16.16 24v-6.4h1.159v5.428h2.819V24z"
						fill="#fff"
					/>
				</svg>
			);
		case 'file':
			return (
				<svg
					width={32}
					height={32}
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M24.2 31.4H7.8a4.2 4.2 0 0 1-4.2-4.2V4.8A4.2 4.2 0 0 1 7.8.6h8.671a4.2 4.2 0 0 1 2.947 1.207l7.729 7.61A4.2 4.2 0 0 1 28.4 12.41V27.2a4.2 4.2 0 0 1-4.2 4.2Z"
						fill="#fff"
						stroke="#CACFD8"
						strokeWidth={1.2}
					/>
					<path
						d="M18 1v6.8a3.2 3.2 0 0 0 3.2 3.2H28"
						stroke="#CACFD8"
						strokeWidth={1.2}
					/>
					<path
						d="M0 17a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3z"
						fill="#FF8447"
					/>
					<text
						x={12}
						y={23}
						fontFamily="Arial, sans-serif"
						fontSize={9}
						fill="#fff"
						textAnchor="middle"
						dominantBaseline="middle"
					>
						{fileType}
					</text>
				</svg>
			);
		case 'text':
			return (
				<svg
					width={32}
					height={32}
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M24.2 31.4H7.8a4.2 4.2 0 0 1-4.2-4.2V4.8A4.2 4.2 0 0 1 7.8.6h8.671a4.2 4.2 0 0 1 2.947 1.207l7.729 7.61A4.2 4.2 0 0 1 28.4 12.41V27.2a4.2 4.2 0 0 1-4.2 4.2Z"
						fill="#fff"
						stroke="#CACFD8"
						strokeWidth={1.2}
					/>
					<path
						d="M18 1v6.8a3.2 3.2 0 0 0 3.2 3.2H28"
						stroke="#CACFD8"
						strokeWidth={1.2}
					/>
					<path
						d="M0 17a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3z"
						fill="#FF8447"
					/>
					<text
						x={12}
						y={23}
						fontFamily="Arial, sans-serif"
						fontSize={9}
						fill="#fff"
						textAnchor="middle"
						dominantBaseline="middle"
					>
						{'TEXT'}
					</text>
				</svg>
			);
		default:
			return null;
	}
};
