import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Modal } from 'antd';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiEdit3 } from 'react-icons/fi';
import { MdDeleteOutline, MdOutlineTextFields } from 'react-icons/md';
import { TbListNumbers } from 'react-icons/tb';
import { VscSymbolBoolean, VscSymbolNumeric } from 'react-icons/vsc';
import axios from '../../config/axios';
import { AIAssistant, PostAnalysis } from '../../types/ai-assistant.types';
import AddPostAnalysisModal from './add-post-analysis-modal';
import { getTitle } from './post-analysis-utils';
import UpdatePostAnalysis from './update-post-analysis';

type Props = {
	assistant: AIAssistant;
	refetchAssistant: () => void;
};

const getIcon = (type: PostAnalysis['type']) => {
	switch (type) {
		case 'string':
			return <MdOutlineTextFields size={18} />;
		case 'enum':
			return <TbListNumbers size={18} />;
		case 'boolean':
			return <VscSymbolBoolean size={18} />;
		case 'number':
			return <VscSymbolNumeric size={16} />;
	}
};

type Loading = {
	delete: boolean;
	index: number | null;
};

const AssistantPostAnalysis: FC<Props> = (props) => {
	const { assistant } = props;
	const [selectedType, setSelectedType] = useState<PostAnalysis['type'] | null>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [modal, contextHolder] = Modal.useModal();
	const [loading, setLoading] = useState<Loading>({
		delete: false,
		index: null,
	});

	return (
		<>
			{contextHolder}

			<div className="text-base font-bold">Post Call Analysis</div>
			<div>Define the information that you need to extract from the call.</div>

			{assistant.post_analysis && assistant.post_analysis.length > 0 ? (
				<div className="flex flex-col gap-2 pt-3">
					{assistant.post_analysis.map((analysis, index) => (
						<div
							key={index}
							className="w-full rounded-md bg-gray-100 p-2 sm:w-96 dark:bg-dark-border"
						>
							<div className="flex items-center justify-between">
								<div className="flex items-center gap-2">
									{getIcon(analysis.type)}
									<p className="text-base font-medium text-gray-500 dark:text-gray-300">{analysis.name}</p>
								</div>

								<div className="flex items-center gap-2">
									<div
										role="button"
										onClick={() => {
											modal.info({
												title: getTitle(analysis.type),
												closable: true,
												icon: null,
												centered: true,
												footer: null,
												width: 520,
												destroyOnClose: true,
												content: (
													<UpdatePostAnalysis
														assistant={assistant}
														analysis={analysis}
														index={index}
														refetchAssistant={props.refetchAssistant}
													/>
												),
											});
										}}
									>
										<FiEdit3 size={17} />
									</div>
									<div
										role="button"
										onClick={async () => {
											// get post analysis array
											const postAnalysis = [...(assistant.post_analysis as PostAnalysis[])];

											// remove the selected post analysis
											postAnalysis.splice(index, 1);

											// update the assistant with the new post analysis array
											try {
												setLoading({ delete: true, index });
												await axios.patch(
													`/ai-assistants/${assistant.assistant_id}`,
													{
														post_analysis: postAnalysis,
													},
													{ withCredentials: true },
												);
												toast.success('Post Analysis Deleted Successfully.');
												props.refetchAssistant();
											} catch (error) {
												if (error instanceof AxiosError && error.response) {
													toast.error(error.response.data.msg);
												} else {
													toast.error('Internal Server Error.');
												}
											} finally {
												setLoading({ delete: false, index: null });
											}
										}}
									>
										{loading.delete && loading.index === index ? (
											<AiOutlineLoading3Quarters
												size={17}
												className="animate-spin"
											/>
										) : (
											<MdDeleteOutline size={18} />
										)}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : null}

			<div className="pt-4" />

			<Dropdown
				className="w-32"
				menu={{
					items: [
						{
							label: (
								<div className="flex items-center gap-2">
									<MdOutlineTextFields size={18} />
									<p className="text-base font-medium">Text</p>
								</div>
							),
							key: 'string',
						},
						{
							label: (
								<div className="flex items-center gap-2">
									<TbListNumbers size={18} />
									<p className="text-base font-medium">Selector</p>
								</div>
							),
							key: 'enum',
						},
						{
							label: (
								<div className="flex items-center gap-2">
									<VscSymbolBoolean size={18} />
									<p className="text-base font-medium">Boolean</p>
								</div>
							),
							key: 'boolean',
						},
						{
							label: (
								<div className="flex items-center gap-2">
									<VscSymbolNumeric size={16} />
									<p className="text-base font-medium">Number</p>
								</div>
							),
							key: 'number',
						},
					],
					onClick: ({ key }) => {
						setModalOpen(true);
						setSelectedType(key as PostAnalysis['type']);
					},
				}}
				trigger={['click']}
				rootClassName="w-44"
			>
				<Button
					icon={<PlusOutlined />}
					size="large"
				>
					Add
				</Button>
			</Dropdown>

			{selectedType && (
				<AddPostAnalysisModal
					open={modalOpen}
					close={() => setModalOpen(false)}
					assistant={assistant}
					type={selectedType}
					refetchAssistant={props.refetchAssistant}
				/>
			)}
		</>
	);
};

export default AssistantPostAnalysis;
