import { LayoutOutlined, TableOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Badge, Button, Card, Divider, Form, Input, Modal, Popover, Select, Table, Tooltip } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsChevronDown } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa6';
import { FiPhoneIncoming, FiPhoneOutgoing } from 'react-icons/fi';
import { GoCheckCircleFill } from 'react-icons/go';
import { IoFilter } from 'react-icons/io5';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import CallAnalysisCard from '../components/call-logs/call-analysis-card.tsx';
import CallDurationCard from '../components/call-logs/call-duration-card.tsx';
import CallInformationCard from '../components/call-logs/call-information-card.tsx';
import CallLogsDrawer from '../components/call-logs/call-logs-drawer.tsx';
import CallTranscriptCard from '../components/call-logs/call-transcript-card.tsx';
import LogDetailsTop from '../components/call-logs/log-details-top.tsx';
import { capitalize, colorBasedOnSentiment } from '../components/call-logs/utils.tsx';
import Loader from '../components/Loader';
import axios from '../config/axios';
import { cn } from '../config/cn.ts';
import dayjs from '../config/dayjs';
import AppLayout from '../layouts/app.layout';
import { AIAssistant } from '../types/ai-assistant.types.ts';
import { Voice } from '../types/voices.types.ts';
import { formatSeconds } from '../utils/helpers.ts';

export type CallLog = {
	call_id: string;
	from_number: string;
	to_number: string;
	assistant_id: string;
	assistant_name: string;
	voice_id: string;
	voice_name: string;
	start_time: Date;
	end_time: Date;
	call_duration_seconds: number;
	call_type: string;
	transcript: string;
	transcript_object: TranscriptObject[];
	status: string;
	provider_call_id: string;
	metadata: Metadata;
	call_recording_enabled: boolean;
	call_recording_url: string;
	call_analysis: CallAnalysis | null;
	protocol: string;
	sentiment: 'positive' | 'negative' | 'neutral' | 'n/a' | null;
};

export type CallAnalysis = {
	summary: string;
	call_completion_summary: string;
	call_completion: string;
	task_completion_summary: string;
	task_completion: string;
	post_analysis_data: {
		name: string;
		value: string | number | boolean;
	}[];
};

export type Metadata = {
	model: string;
};

export type TranscriptObject = {
	role: string;
	content: string;
	name?: string;
};

const CallLogs = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [history, setHistory] = useState<CallLog | undefined>();
	const [historyLoading, setHistoryLoading] = useState(true);
	const [wavesurfer, setWavesurfer] = useState<unknown>();
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [logs, setLogs] = useState<CallLog[]>([]);
	const [count, setCount] = useState(0);
	const [wavePercent, setWavePercent] = useState(0);
	const [filterOpen, setFilterOpen] = useState<boolean>(false);
	const [filterLoading, setFilterLoading] = useState<boolean>(false);
	const [filters, setFilters] = useState<object>({});
	const [logsModalOpen, setLogsModalOpen] = useState(false);
	const { width } = useWindowSize();
	const [copied, setCopied] = useState<boolean>(false);
	const [copiedId, setCopiedId] = useState<string | null>(null);
	const [viewMode, setViewMode] = useState<'list' | 'grid'>('grid');
	const [logDrawer, setLogDrawer] = useState<{
		open: boolean;
		data: CallLog | null;
	}>({
		open: false,
		data: null,
	});

	const toggleViewMode = () => {
		setWavePercent(0);
		setViewMode((prev) => (prev === 'list' ? 'grid' : 'list'));
	};

	// get phone number from url
	const selected_call = searchParams.get('history');

	// get all call logs
	useEffect(() => {
		(async () => {
			const isFilterPresent = filters && Object.keys(filters).length > 0;
			if (isFilterPresent) {
				setFilterLoading(true);
			}

			try {
				const { data } = await axios.get('/calls', {
					withCredentials: true,
					params: {
						page,
						limit: 20,
						...filters,
					},
				});
				setCount(data.count);

				if (viewMode === 'list') {
					setLogs(data.results);
				}

				if (viewMode === 'grid') {
					if (page === 1) {
						setLogs(data.results);
					} else {
						setLogs((prev) => [...prev, ...data.results]);
					}
				}
			} finally {
				setLoading(false);
				setFilterLoading(false);
				if (isFilterPresent) {
					setFilterOpen(false);
				}
			}
		})();
	}, [page, filters, viewMode]);

	// set first call as default
	useEffect(() => {
		if (!searchParams.get('history') && logs.length > 0) {
			setSearchParams({
				history: String(logs[0].call_id),
			});
		}
	}, [logs, searchParams, setSearchParams]);

	// get call history details
	useEffect(() => {
		setHistoryLoading(true);
		setWavesurfer(undefined);
		setWavePercent(0);
		(async () => {
			if (selected_call) {
				const { data } = await axios.get(`/calls/${selected_call}`, { withCredentials: true });
				setHistory(data.results);
				setHistoryLoading(false);
			}
		})();
	}, [selected_call]);

	useEffect(() => {
		if (wavesurfer) {
			(wavesurfer as { stop: () => void }).stop();
		}
	}, [wavesurfer, selected_call]);

	// get all voices
	const { data: voices } = useQuery<Voice[]>({
		queryKey: ['voices-query'],
		queryFn: async () => {
			const { data } = await axios.get('/voices', { withCredentials: true, params: { limit: 50 } });

			return data.results;
		},
	});

	// get all ai assistants for select
	const { data: assistants } = useQuery({
		queryKey: ['ai-assistants-select'],
		queryFn: async () => {
			const { data } = await axios.get('/ai-assistants', { withCredentials: true, params: { limit: 20 } });

			return data.results.map((assistant: AIAssistant) => {
				return {
					label: assistant.name,
					value: assistant.assistant_id,
				};
			});
		},
	});

	// reset copied state after 2 seconds
	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [copied]);

	// filter popover
	const filterPopover = (
		<Popover
			title="Build Your Query"
			arrow={false}
			placement={width < 768 ? 'bottom' : 'bottomLeft'}
			overlayInnerStyle={{ width: 350, marginLeft: width < 768 ? 5 : 0 }}
			trigger="click"
			{...(width > 768 && {
				open: filterOpen,
				onOpenChange: (open) => setFilterOpen(open),
			})}
			content={
				<Form
					onFinish={(values) => {
						setPage(1);
						setFilters(values);
					}}
					layout="vertical"
				>
					<Form.Item
						label={<div className="font-medium">Call Type</div>}
						className="mb-2"
						name="call_type"
					>
						<Select
							options={[
								{ label: 'Incoming', value: 'incoming' },
								{
									label: 'Outgoing',
									value: 'outgoing',
								},
							]}
							placeholder="Select Call Type"
						/>
					</Form.Item>
					<Form.Item
						label={<div className="font-medium">Protocol</div>}
						className="mb-2"
						name="protocol"
					>
						<Select
							options={[
								{ label: 'VOIP', value: 'voip' },
								{
									label: 'Web',
									value: 'web',
								},
							]}
							placeholder="Select Protocol"
						/>
					</Form.Item>

					<Form.Item
						label={<div className="font-medium">Sentiment</div>}
						className="mb-2"
						name="sentiment"
					>
						<Select
							options={[
								{ label: 'Positive', value: 'positive' },
								{
									label: 'Negative',
									value: 'negative',
								},
								{
									label: 'Neutral',
									value: 'neutral',
								},
							]}
							placeholder="Select Sentiment"
						/>
					</Form.Item>
					<Form.Item
						label={<div className="font-medium">Status</div>}
						className="mb-2"
						name="status"
					>
						<Select
							options={[
								{ label: 'Completed', value: 'completed' },
								{
									label: 'In Progress',
									value: 'in progress',
								},
								{
									label: 'Failed',
									value: 'failed',
								},
							]}
							placeholder="Select Status"
						/>
					</Form.Item>
					<Form.Item
						label={<div className="font-medium">Voice</div>}
						className="mb-2"
						name="voice_id"
					>
						<Select
							options={voices?.map((voice) => ({ label: voice.name, value: voice.voice_id }))}
							placeholder="Select Voice"
							virtual={false}
						/>
					</Form.Item>
					<Form.Item
						label={<div className="font-medium">AI Assistant</div>}
						className="mb-2"
						name="assistant_id"
					>
						<Select
							options={assistants || []}
							placeholder="Select AI Assistant"
							virtual={false}
						/>
					</Form.Item>
					<Form.Item
						label={<div className="font-medium">Call ID</div>}
						className="mb-2"
						name="call_id"
					>
						<Input placeholder="Enter call ID" />
					</Form.Item>
					<Form.Item
						label={<div className="font-medium">From Number</div>}
						className="mb-2"
						name="from_number"
					>
						<Input placeholder="Enter From Number" />
					</Form.Item>
					<Form.Item
						label={<div className="font-medium">To Number</div>}
						className="mb-4"
						name="to_number"
					>
						<Input placeholder="Enter To Number" />
					</Form.Item>
					<Form.Item
						className="mb-0"
						{...(width < 768 && {
							extra: <div className="pt-1 text-red-500">Close popup when loading is finished.</div>,
						})}
					>
						<div className="grid grid-cols-2 gap-2.5">
							<Button
								type="dashed"
								className="font-medium"
								htmlType="reset"
								onClick={() => {
									setPage(1);
									setFilters({});
									setFilterOpen(false);
								}}
							>
								Reset
							</Button>
							<Button
								type="primary"
								className="font-medium"
								htmlType="submit"
								loading={filterLoading}
								disabled={filterLoading}
							>
								Filter
							</Button>
						</div>
					</Form.Item>
				</Form>
			}
		>
			<Button
				icon={<IoFilter />}
				className="font-medium"
			>
				Filter
			</Button>
		</Popover>
	);

	return (
		<AppLayout
			title="Call Logs"
			subtitle="View and manage call logs here."
		>
			{loading && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full items-center justify-center">
						<Loader />
					</div>
				</Card>
			)}

			{!loading && logs.length === 0 && (
				<Card styles={{ body: { padding: 0, height: 'calc(100dvh - 8rem)' } }}>
					<div className="flex h-full flex-col items-center justify-center gap-2">
						<div className="text-base font-medium text-black-3">No call logs found.</div>
						{filters && Object.keys(filters).length > 0 && (
							<div
								className="font-medium text-primary"
								role="button"
								onClick={() => {
									setPage(1);
									setFilters({});
								}}
							>
								Reset filters.
							</div>
						)}
					</div>
				</Card>
			)}

			{!loading && logs.length > 0 && viewMode === 'list' && (
				<>
					<Table
						title={() => (
							<div className="flex w-[19.3rem] items-center justify-between px-0.5">
								<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Call History</div>

								<div className={'flex items-center gap-1.5'}>
									{filterPopover}

									<Button
										icon={<LayoutOutlined />}
										onClick={toggleViewMode}
										title={'Switch to Grid View'}
									/>
								</div>
							</div>
						)}
						dataSource={logs}
						size={'middle'}
						bordered={true}
						pagination={{
							pageSize: 20,
							total: count,
							showSizeChanger: false,
							onChange: (page) => {
								setPage(page);
							},
						}}
						rowKey={(record) => record.call_id}
						onRow={(record) => {
							return {
								onClick: () => {
									setWavePercent(0);
									setLogDrawer({
										open: true,
										data: record,
									});
								},
							};
						}}
						rowClassName={'cursor-pointer'}
						columns={[
							{
								title: 'Time',
								dataIndex: 'start_time',
								key: 'start_time',
								render: (start_time: Date) => dayjs(start_time).format('YYYY-MM-DD hh:mm'),
							},
							{
								title: 'Type',
								dataIndex: 'call_type',
								key: 'call_type',
								render: (call_type: string) => (call_type === 'incoming' ? 'Incoming' : 'Outgoing'),
							},
							{
								title: 'Protocol',
								dataIndex: 'protocol',
								key: 'protocol',
								render: (protocol: string) => (['twilio', 'telnyx'].includes(protocol) ? 'VOIP Call' : 'Web Call'),
							},
							{
								title: 'Call Duration',
								dataIndex: 'call_duration_seconds',
								key: 'call_duration_seconds',
								render: (call_duration_seconds: number) => formatSeconds(call_duration_seconds),
							},
							{
								title: 'Call ID',
								dataIndex: 'call_id',
								key: 'call_id',
								render: (call_id: string) => (
									<div className="flex items-center gap-2">
										<div>{call_id}</div>
										<div
											onClick={async (e) => {
												e.stopPropagation();
												await navigator.clipboard.writeText(call_id);
												setCopied(true);
												setCopiedId(call_id);
												toast.success('Copied to clipboard.');
											}}
											role="button"
										>
											<div className="text-sm text-gray-500">
												{copied && copiedId === call_id ? <GoCheckCircleFill color="green" /> : <FaRegCopy />}
											</div>
										</div>
									</div>
								),
							},
							{
								title: 'Assistant',
								dataIndex: 'assistant_name',
								key: 'assistant_name',
							},
							{
								title: 'Sentiment',
								dataIndex: 'sentiment',
								key: 'sentiment',
								render: (sentiment: string) => (
									<Badge
										color={colorBasedOnSentiment(sentiment)}
										className={'font-medium capitalize *:!text-black-3'}
										{...(sentiment === null
											? {
													status: 'processing',
													text: 'Generating',
												}
											: {
													text: sentiment,
												})}
									/>
								),
							},
						]}
					/>
				</>
			)}

			{!loading && logs.length > 0 && viewMode === 'grid' && (
				<div className="flex flex-col gap-5 md:flex-row">
					{/* mobile version */}
					<div className="md:hidden">
						{logs.map((log, i) => {
							if (log.call_id === searchParams.get('history')) {
								return (
									<Card
										key={i}
										styles={{ body: { padding: 10 } }}
										role="button"
										className="shadow-sm"
										onClick={() => {
											// open modal
											setLogsModalOpen(true);
										}}
									>
										<div className="flex items-center justify-between gap-2">
											<div className="flex items-center gap-2.5">
												<img
													src="/images/call-logs/call-logs.svg"
													alt="call-logs"
												/>

												<section className="flex flex-col">
													<div className="text-base font-medium capitalize text-black-7 dark:text-white">
														{['twilio', 'telnyx'].includes(log.protocol) ? 'VOIP Call' : 'Web Call'}
													</div>
													<div className="text-sm font-medium text-black-3">
														{dayjs(log.start_time).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>
											</div>

											<div>
												<BsChevronDown size={20} />
											</div>
										</div>
									</Card>
								);
							}
							return null;
						})}

						{/* logs modal */}
						<Modal
							title={
								<div className="flex items-center gap-4">
									<div>Call History</div>
									<div>{filterPopover}</div>
								</div>
							}
							open={logsModalOpen}
							onCancel={() => setLogsModalOpen(false)}
							footer={null}
							centered
						>
							<div className="h-[calc(100dvh-10rem)] overflow-hidden">
								<div
									className="scrollbar-hidden h-full overflow-y-auto"
									id="log-scrollable-mobile"
								>
									<InfiniteScroll
										dataLength={logs.length}
										next={
											logs.length < count
												? () => {
														setPage((prev) => prev + 1);
													}
												: () => {}
										}
										hasMore={count > logs.length}
										style={{
											overflow: 'hidden',
										}}
										loader={
											<div className="my-3 flex items-center justify-center">
												<Loader />
											</div>
										}
										scrollThreshold={0.9}
										scrollableTarget="log-scrollable-mobile"
									>
										<div className="pt-3" />

										<div className="flex flex-col gap-3.5">
											{logs.map((log, i) => (
												<div
													key={i}
													className={cn(
														'rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
														{
															'border-primary': searchParams.get('history') === log.call_id,
															'dark:border-dark-border': searchParams.get('history') !== log.call_id,
														},
													)}
													onClick={() => {
														setSearchParams({
															history: log.call_id,
														});
														setLogsModalOpen(false);
													}}
													role="button"
												>
													<div className="flex items-center gap-2.5">
														<img
															src="/images/call-logs/call-logs.svg"
															alt="call-logs"
														/>

														<section className="flex flex-col">
															<div className="text-base font-medium capitalize text-black-7 dark:text-white">
																{['twilio', 'telnyx'].includes(log.protocol) ? 'VOIP Call' : 'Web Call'}
															</div>
															<div className="text-sm font-medium text-black-3">
																{dayjs(log.start_time).format('YYYY-MM-DD hh:mm')}
															</div>
														</section>
													</div>
												</div>
											))}
										</div>
									</InfiniteScroll>
								</div>
							</div>
						</Modal>
					</div>

					{/* desktop version */}
					<div className="sticky top-28 hidden h-[calc(100dvh-8rem)] w-[21rem] overflow-hidden md:block">
						<Card
							className="h-full overflow-y-auto bg-input-bg dark:bg-dark-sidebar"
							styles={{ body: { padding: 0 } }}
							id="log-scrollable"
						>
							<div className="flex items-center justify-between rounded-t-lg border-b bg-white px-3 py-[12px] dark:border-b-[#2d2b38] dark:bg-[#161422]">
								<div className="text-xl font-semibold leading-none text-black-7 dark:text-white">Call History</div>

								<div className={'flex items-center gap-1.5'}>
									{filterPopover}

									<Button
										icon={<TableOutlined />}
										onClick={toggleViewMode}
										title={'Switch to Table View'}
									/>
								</div>
							</div>

							<InfiniteScroll
								dataLength={logs.length}
								next={
									logs.length < count
										? () => {
												setPage((prev) => prev + 1);
											}
										: () => {}
								}
								hasMore={count > logs.length}
								style={{
									overflow: 'hidden',
								}}
								loader={
									<div className="my-3 flex items-center justify-center">
										<Loader />
									</div>
								}
								scrollThreshold={0.9}
								scrollableTarget="log-scrollable"
							>
								<div className="flex flex-col gap-3.5 p-3">
									{logs.map((log: CallLog, i: number) => (
										<div
											key={i}
											className={cn(
												'relative rounded-lg border bg-white px-2 py-2.5 shadow-sm transition-all duration-300 dark:bg-[#161422]',
												{
													'border-primary': searchParams.get('history') === log.call_id,
													'dark:border-[#14121f]': searchParams.get('history') !== log.call_id,
												},
											)}
											onClick={() => {
												setSearchParams({
													history: log.call_id,
												});
											}}
											role="button"
										>
											<div className="flex items-center gap-2.5">
												<img
													src="/images/call-logs/call-logs.svg"
													alt="call-logs"
												/>

												<section className="flex flex-col">
													<div className="text-base font-medium capitalize text-black-7 dark:text-white">
														{['twilio', 'telnyx'].includes(log.protocol) ? 'VOIP Call' : 'Web Call'}
													</div>
													<div className="text-sm font-medium text-black-3">
														{dayjs(log.start_time).format('YYYY-MM-DD hh:mm')}
													</div>
												</section>
												{log.call_type === 'incoming' ? (
													<div className={'ml-auto mr-4 text-green-500'}>
														<FiPhoneIncoming size={16} />
													</div>
												) : (
													<div className={'ml-auto mr-4 text-primary'}>
														<FiPhoneOutgoing size={16} />
													</div>
												)}
											</div>

											<div className="absolute right-1.5 top-0">
												<Tooltip
													{...(log.sentiment === null
														? {
																title: 'Sentiment: Generating',
															}
														: log.sentiment === 'n/a'
															? {
																	title: 'Sentiment: N/A',
																}
															: {
																	title: `Sentiment: ${capitalize(log.sentiment as string)}`,
																})}
													placement="top"
													arrow={false}
												>
													<Badge
														color={colorBasedOnSentiment(log.sentiment as string)}
														className={'font-medium capitalize *:!text-black-3'}
														{...(log.sentiment === null
															? {
																	status: 'processing',
																}
															: null)}
													/>
												</Tooltip>
											</div>
										</div>
									))}
								</div>
							</InfiniteScroll>
						</Card>
					</div>
					<div className="flex-1 pt-2">
						<div className="h-full">
							{selected_call ? (
								<Fragment>
									{historyLoading && (
										<div className="flex h-[calc(100dvh-20rem)] items-center justify-center md:h-[calc(100dvh-9rem)]">
											<Loader />
										</div>
									)}

									{!historyLoading && history && (
										<section>
											<LogDetailsTop
												history={history}
												wavePercent={wavePercent}
												setWavePercent={setWavePercent}
											/>

											<Divider />

											<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
												<CallInformationCard history={history} />

												<CallDurationCard history={history} />
											</div>

											<div
												className={cn('pt-6', {
													'pt-0': !history.call_analysis,
												})}
											/>

											<CallAnalysisCard history={history} />

											<div className="pt-6" />

											<CallTranscriptCard history={history} />
										</section>
									)}

									<div className="pb-6" />
								</Fragment>
							) : (
								<Card className="flex h-full items-center justify-center">
									<div className="flex flex-col gap-2 text-center">
										<div className="text-base font-medium text-gray-600 dark:text-gray-300">Select a log to view details.</div>
									</div>
								</Card>
							)}
						</div>
					</div>
				</div>
			)}

			{logDrawer.data ? (
				<CallLogsDrawer
					open={logDrawer.open}
					close={() => setLogDrawer((prev) => ({ ...prev, open: false }))}
					history={logDrawer.data}
					wavePercent={wavePercent}
					setWavePercent={setWavePercent}
				/>
			) : null}
		</AppLayout>
	);
};

export default CallLogs;
