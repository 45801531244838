import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Select, Slider, Switch } from 'antd';
import { FC, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { useThemeStore } from '../../store/theme';
import { AIAssistant } from '../../types/ai-assistant.types';

type Props = {
	assistant: AIAssistant;
	refetch: () => void;
	refetchAssistant: () => void;
};

const AssistantGeneralSettings: FC<Props> = (props) => {
	const { is_dark_mode } = useThemeStore();
	const [recordingEnabled, setRecordingEnabled] = useState<boolean>(() => props.assistant.call_recording_enabled);
	const [maximumReminderMessage, setMaximumReminderMessage] = useState<number>(() => props.assistant.max_reminder_messages);
	const [reminderMessageFrequency, setReminderMessageFrequency] = useState<number>(() => props.assistant.reminder_message_frequency);
	const [timezone, setTimezone] = useState<string>(() => props.assistant.timezone);

	// get all timezones
	const { data: timezones } = useQuery<string[]>({
		queryKey: ['timezones'],
		queryFn: async () => {
			const { data } = await axios.get('/commons/timezones', { withCredentials: true });

			return data.results;
		},
	});

	// config for options
	const configs = [
		{
			title: 'Reminder Message Frequency (sec)',
			description: 'How often should the assistant remind the user about the conversation?',
			value: reminderMessageFrequency,
			min: 10,
			max: 20,
			step: 5,
			leftLabel: '10 (sec)',
			rightLabel: '20 (sec)',
			onChange: (value: number) => {
				setReminderMessageFrequency(value);
			},
			onIncrement: () => {
				if (reminderMessageFrequency < 20) {
					setReminderMessageFrequency((prev) => Math.min(prev + 5, 20));
				}
			},
			onDecrement: () => {
				if (reminderMessageFrequency > 10) {
					setReminderMessageFrequency((prev) => Math.max(prev - 5, 10));
				}
			},
		},
		{
			title: 'Maximum Reminder Message',
			description: 'How many times should the assistant remind the user about the conversation?',
			value: maximumReminderMessage,
			min: 1,
			max: 5,
			leftLabel: '1',
			rightLabel: '5',
			onChange: (value: number) => {
				setMaximumReminderMessage(value);
			},
			onIncrement: () => {
				if (maximumReminderMessage < 5) {
					setMaximumReminderMessage((prev) => Math.min(prev + 1, 5));
				}
			},
			onDecrement: () => {
				if (maximumReminderMessage > 1) {
					setMaximumReminderMessage((prev) => Math.max(prev - 1, 1));
				}
			},
		},
	];

	// update general settings mutation
	const { mutate, isPending } = useMutation({
		mutationKey: ['update-general-settings'],
		mutationFn: async (payload: Record<string, unknown>) => {
			await axios.patch(`/ai-assistants/${props.assistant.assistant_id}`, payload, { withCredentials: true });
		},
		onSuccess: () => {
			toast.success('General settings updated successfully');
			props.refetch();
			props.refetchAssistant();
		},
	});

	return (
		<Fragment>
			<div className="flex items-center justify-between gap-2">
				<h2 className="text-xl font-semibold text-black-7 dark:text-white">General Settings</h2>

				<Button
					type="primary"
					htmlType="submit"
					size="large"
					className="font-semibold"
					disabled={isPending}
					loading={isPending}
					icon={
						<img
							src="/images/ai-assistant/save-icon.svg"
							alt="save-icon"
						/>
					}
					onClick={() => {
						const payload = {
							call_recording_enabled: recordingEnabled,
							reminder_message_frequency: reminderMessageFrequency,
							max_reminder_messages: maximumReminderMessage,
							timezone,
						};

						mutate(payload);
					}}
				>
					Save
				</Button>
			</div>

			<div className="pt-6" />

			<div className="grid grid-cols-1 gap-x-8 gap-y-6 2xl:grid-cols-2">
				<div className="text-sm font-medium text-black-3">
					Control the assistant's behavior by adjusting the following advanced settings. It is recommended to keep the default settings.
				</div>

				<div>
					<div className="text-base font-medium text-black-7 dark:text-white">Recording Enabled</div>

					<div className="pt-1.5" />

					<div className="flex items-center justify-between gap-3.5">
						<div className="text-sm font-medium text-black-3">Record the conversation with the assistant.</div>
						<Switch
							checked={recordingEnabled}
							onChange={(value) => setRecordingEnabled(value)}
						/>
					</div>
				</div>
				{configs.map((item, index) => (
					<div key={index}>
						<div className="text-base font-medium text-black-7 dark:text-white">{item.title}</div>
						<p className="text-sm font-medium text-black-3">{item.description}</p>

						<div className="flex items-start gap-3">
							<div className="flex-1">
								<Slider
									value={item.value}
									min={item.min}
									max={item.max}
									onChange={item.onChange}
									{...(item.step && { step: item.step })}
								/>

								<div className="-mt-1 flex justify-between gap-2 px-1.5 text-sm font-medium text-black-3">
									<div>{item.leftLabel}</div>
									<div>{item.rightLabel}</div>
								</div>
							</div>

							<div className="flex w-fit items-center gap-2 pt-1.5">
								<img
									src={is_dark_mode ? '/images/ai-assistant/minus-dark.svg' : '/images/ai-assistant/minus.svg'}
									alt="minus"
									role="button"
									onClick={item.onDecrement}
								/>

								<div className="rounded-md border px-4 py-0.5 text-sm font-medium dark:border-[#161422] dark:bg-[#1b1827] dark:text-white">
									{item.value}
									{item.title === 'Reminder Message Frequency (sec)' ? 's' : ''}
								</div>

								<img
									src={is_dark_mode ? '/images/ai-assistant/plus-dark.svg' : '/images/ai-assistant/plus.svg'}
									alt="plus"
									role="button"
									onClick={item.onIncrement}
								/>
							</div>
						</div>
					</div>
				))}

				<div>
					<div className="text-base font-medium text-black-7 dark:text-white">Timezone</div>

					<div className="pt-1.5" />

					<Select
						options={timezones?.map((item) => ({ label: item, value: item }))}
						value={timezone}
						onChange={(value) => setTimezone(value)}
						className="w-full"
						size="large"
						placeholder="Select Timezone"
						virtual={false}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default AssistantGeneralSettings;
