import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Modal } from 'antd';
import { AxiosError } from 'axios';
import { nanoid } from 'nanoid';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsLink45Deg } from 'react-icons/bs';
import { CiTextAlignCenter } from 'react-icons/ci';
import { IoCloudUploadOutline } from 'react-icons/io5';
import axios from '../../config/axios';
import AddTextModal from './add-text-modal';
import AddWebPageModal from './add-webpage-modal';
import DropdownLabel from './dropdown-label';
import { getIconsForType } from './utils';

type Props = {
	open: boolean;
	close: () => void;
	refresh: () => void;
};

export type Document = {
	id: string;
	name: string;
	type: 'url' | 'file' | 'text';
	content?: string | string[];
	fileType?: string;
};

const CreateKnowledgeBaseModal: FC<Props> = (props) => {
	const [knowledgeBaseName, setKnowledgeBaseName] = useState('');
	const [documents, setDocuments] = useState<Document[]>([]);
	const [addTextModalOpen, setAddTextModalOpen] = useState(false);
	const [addWebPageModalOpen, setAddWebPageModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	return (
		<Modal
			title="Add Knowledge Base"
			open={props.open}
			onCancel={props.close}
			centered
			afterClose={() => {
				setKnowledgeBaseName('');
				setDocuments([]);
			}}
			okButtonProps={{
				onClick: async () => {
					// prepare payload
					const payload = {
						name: knowledgeBaseName,
						urls: documents.filter((doc) => doc.type === 'url').flatMap((doc) => doc.content),
						files:
							documents
								.filter((doc) => doc.type === 'file')
								.map((doc) => {
									return {
										name: doc.name,
										url: doc.content,
									};
								}) || [],
						texts:
							documents
								.filter((doc) => doc.type === 'text')
								.map((doc) => {
									return {
										name: doc.name,
										text: doc.content,
									};
								}) || [],
					};

					try {
						setLoading(true);
						await axios.post('/knowledge-base', payload, { withCredentials: true });
						toast.success('Knowledge Base Created');
						props.refresh();
					} catch (error) {
						if (error instanceof AxiosError && error.response) {
							toast.error(error.response.data.msg);
						}
						toast.error('Error Creating Knowledge Base');
					} finally {
						setLoading(false);
						props.close();
					}
				},
				size: 'large',
				disabled: documents.length === 0 || knowledgeBaseName === '',
				loading: loading,
			}}
			cancelButtonProps={{
				size: 'large',
			}}
			okText="Save"
			width={800}
			destroyOnClose
		>
			<div className="pt-2" />

			<div>
				<label
					htmlFor="name"
					className="text-base font-medium"
				>
					Knowledge Base Name
				</label>

				<div className="pt-2" />

				<Input
					id="name"
					className="w-full"
					size="large"
					value={knowledgeBaseName}
					onChange={(e) => setKnowledgeBaseName(e.target.value)}
					placeholder="Enter knowledge base name"
				/>
			</div>

			<div className="pt-4" />

			<div>
				<label
					htmlFor="documents"
					className="text-base font-medium"
				>
					Documents
				</label>

				<div className="pt-3" />

				{documents.length > 0 && (
					<div className="flex flex-wrap gap-2 pb-4">
						{documents.map((document, i) => (
							<div
								key={i}
								className="flex w-full items-center justify-between rounded-md border px-4 py-3 dark:border-dark-border"
							>
								<div className="flex items-center gap-2">
									<div>
										<div>{getIconsForType(document.type, document.fileType)}</div>
									</div>
									<div className="flex flex-col">
										<div className="text-lg font-semibold">{document.name}</div>
										{document.type === 'url' && (
											<div className="text-sm font-medium text-gray-500">
												{Array.isArray(document.content) ? `${document.content.length} Pages` : '1 Page'}
											</div>
										)}
									</div>
								</div>
								<button
									onClick={() => {
										const newDocuments = documents.filter((doc) => doc.id !== document.id);
										setDocuments(newDocuments);
									}}
									className="text-gray-500 transition-colors hover:text-red-500"
								>
									<AiOutlineDelete size={20} />
								</button>
							</div>
						))}
					</div>
				)}

				<Dropdown
					menu={{
						items: [
							{
								label: (
									<DropdownLabel
										icon={<BsLink45Deg size={20} />}
										title="Add Web Pages"
										description="Crawl and sync your website"
									/>
								),
								key: 'url',
							},
							{
								label: (
									<DropdownLabel
										icon={<IoCloudUploadOutline size={20} />}
										title="Upload File"
										description="File size should be less than 100MB"
									/>
								),
								key: 'file',
							},
							{
								label: (
									<DropdownLabel
										icon={<CiTextAlignCenter size={20} />}
										title="Add Text"
										description="Add articles manually"
									/>
								),
								key: 'text',
							},
						],
						onClick: ({ key }) => {
							// handle url click
							if (key === 'url') {
								setAddWebPageModalOpen(true);
							}

							// handle file click
							if (key === 'file') {
								const input = document.createElement('input');
								input.type = 'file';
								input.multiple = false;
								input.accept = 'image/jpeg, image/jpg, image/png, application/pdf, .doc, .docx, .txt';
								input.onchange = async (e) => {
									try {
										const file = (e.target as HTMLInputElement).files?.[0];
										if (file) {
											// if file size is more than 100MB
											if (file.size > 100 * 1024 * 1024) {
												toast.error('File size should be less than 100MB');
												return;
											}

											// upload the file
											const formData = new FormData();
											formData.append('image', file as File);

											// upload image
											toast.loading('Uploading File...');
											const { data } = await axios.post('/commons/upload-image', formData, { withCredentials: true });

											const fileExtension = file.name.split('.').pop()?.toUpperCase() || 'N/A';

											setDocuments((prev) => [
												...prev,
												{
													id: nanoid(),
													name: file.name,
													type: 'file',
													content: data.results.secure_url,
													fileType: fileExtension,
												},
											]);
										}
									} catch (error) {
										if (error instanceof AxiosError && error.response) {
											toast.error(error.response.data.msg);
										}
										toast.error('Error Uploading File');
									} finally {
										toast.dismiss();
									}
								};
								input.click();
							}

							// handle text click
							if (key === 'text') {
								setAddTextModalOpen(true);
							}
						},
					}}
					overlayClassName="w-96 [&>ul]:!p-2"
					trigger={['click']}
				>
					<Button
						icon={<PlusOutlined />}
						size="large"
						className="font-medium"
					>
						Add
					</Button>
				</Dropdown>
			</div>

			{/* Add Text Modal */}
			<AddTextModal
				open={addTextModalOpen}
				close={() => setAddTextModalOpen(false)}
				setDocuments={setDocuments}
			/>

			{/* Add Web Page Modal */}
			<AddWebPageModal
				open={addWebPageModalOpen}
				close={() => setAddWebPageModalOpen(false)}
				setDocuments={setDocuments}
			/>
		</Modal>
	);
};

export default CreateKnowledgeBaseModal;
